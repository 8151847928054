import { Box, Grid, Typography } from "@mui/material";
import { ResearchTypeMedia, useStyles } from "../index";
import Parse from "html-react-parser";
import PropTypes from "prop-types";

const ResearchTypes = ({ researchTypes, selectedType }) => {
  if (researchTypes.length === 0) return;

  const {
    paragraph,
  } = useStyles();

  return (
    <>
      {researchTypes.map((researchType) => {
        if (researchType.title !== selectedType) return;
        return (
          <Box
            key={`type-${researchType.title}-content`}
            sx={{ height: "100%", overflowY: "auto", paddingBottom: "30px"}}
          >
            <Grid item>
              <Typography className={paragraph}>
                {Parse(researchType.content)}
              </Typography>
            </Grid>

            {/* MEDIA */}
            <Grid item>
              <ResearchTypeMedia media={researchType.media}/>
            </Grid>
          </Box>
        );
      })}
    </>
  );
};

export default ResearchTypes;

ResearchTypes.propTypes = {
  researchTypes: PropTypes.array,
  selectedType: PropTypes.string
};