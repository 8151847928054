import {
  getAllStudies,
  getStudyInfoByCancer,
  setMultipleStudyProgramIds,
  setSingleStudyProgramId
} from "../../redux/slices/studyInfoSlice";
import { resetEnrollmentState } from "../../redux/slices/enrollmentSlice";
import { saveDonationInfo } from "../../redux/slices/donationInfoSlice";
import { selectCancers, selectUserCancer } from "../../redux/selectors/cancersSelector";

import {
  selectAllStudies,
  selectSelectedStudyPrograms,
  selectStudyInfoByCancer,
  selectStudyInfoStatus
} from "../../redux/selectors/studyInfoSelector";
import {
  selectScreening
} from "../../redux/selectors/enrollmentSelector";
import { useDispatch, useSelector } from "react-redux";

export const useEnrollment = () => {
  const dispatch = useDispatch();

  const isScreening = useSelector(selectScreening);
  const cancers = useSelector(selectCancers);
  const userCancer = useSelector(selectUserCancer);

  // study
  const studyInfoStatus = useSelector(selectStudyInfoStatus);
  const studyInfoByCancer = useSelector(selectStudyInfoByCancer);
  const allStudies = useSelector(selectAllStudies);
  const fetchStudyInfoByCancer = id => dispatch(getStudyInfoByCancer({ cancer_id: id }));
  const fetchAllStudies = () => dispatch(getAllStudies());
  const selectedStudyPrograms = useSelector(selectSelectedStudyPrograms);
  const setMultipleStudyPrograms = selectedStudyIds => dispatch(setMultipleStudyProgramIds(selectedStudyIds));
  const setSingleStudyProgram = selectedStudyId => dispatch(setSingleStudyProgramId(selectedStudyId));

  const saveDonationInformation = () => dispatch(saveDonationInfo());

  const resetEnrollment = () => dispatch(resetEnrollmentState());



  return {
    isScreening,
    cancers,
    userCancer,
    studyInfoStatus,
    fetchAllStudies,
    studyInfoByCancer,
    fetchStudyInfoByCancer,
    selectedStudyPrograms,
    setMultipleStudyPrograms,
    setSingleStudyProgram,
    saveDonationInformation,
    resetEnrollment,
    allStudies
  };

};