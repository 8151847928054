import { GET_STUDIES } from "shared/constants/endpoints";
import axios from "axios";

export class StudyInfoService {
  static async getStudyList(cancer_id) {
    try {
      const res = await axios.get(GET_STUDIES, { params: { cancer_id } });
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  static async getAllStudies() {
    try {
      const res = await axios.get(GET_STUDIES);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}