import { Grid, Hidden, Paper } from "@mui/material";
import { WelcomingCard, useStyles } from "./index";
import React from 'react';

import ArrowButton from './ArrowButton';



const WelcomingPanel = () => {

  const { root, welcomingCard } = useStyles();

  return (
    <Paper className={root} square>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-evenly"
        alignItems="center"
        className={welcomingCard}
      >
        <WelcomingCard />
        <Hidden mdDown>
          <Grid item xs={5}></Grid>
        </Hidden>
      </Grid>
      <Grid item className="hidden sm:block">
        <ArrowButton
          to="whyDonate"
          position="absolute"
          color="white"
        />
      </Grid>
    </Paper>
  );

};

export default WelcomingPanel;