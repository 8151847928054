import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider,
  Grid, Link,
  Typography,
  useMediaQuery
} from "@mui/material";
import { ExampleUses, ResearchTypes, StudyDescription, useStyles } from "./index";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const StudyDetailsModal = ({ isOpened, onClose, studyDetails }) => {
  if (!studyDetails) return;

  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const isMediumOrLowerScreen = useMediaQuery(theme => theme.breakpoints.down("xl"));

  const { study, research_types } = studyDetails;
  const researchTypeTitles = research_types.map((type) => type.title);

  const {
    root,
    header,
    content,
    button,
    breadcrumb,
    selectedTypeStyle,
    dividerStyle
  } = useStyles();

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  const handleCLose = () => {
    setSelectedType("");
    onClose();
  };

  const getSelectedResearchType = research_types.find(researchType => researchType.title === selectedType);

  const selectedResearchTypeMediaWithExternalLink =
    getSelectedResearchType?.media.filter(mediaItem => mediaItem.type === "external-website");

  const renderStudyDetails = () => (
    <div className="flex flex-col sm:flex-row gap-10" >
      {!selectedType && (
        <>
          {/* EXAMPLE USES */}
          <Grid className="flex flex-col h-full">
            <ExampleUses
              researchTypeTitles={researchTypeTitles}
              onClick={(title) => setSelectedType(title)}
            />
          </Grid>

          {/* STUDY DESCRIPTION */}
          <div className="w-full">
            <StudyDescription description={study.description} />
          </div>
        </>
      )}

      {/* RESEARCH TYPES */}
      {selectedType && (
        <Grid item xs sx={{ height: "100%" }}>
          <ResearchTypes
            researchTypes={research_types}
            selectedType={selectedType}
          />
        </Grid>
      )}

    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={handleCLose}
      maxWidth="xl"
      className={root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={header} style={{ marginTop: isMediumOrLowerScreen ? "32px" : "128px" }}>
        {!selectedType && (
          <>
            <Box mb={5}>
              <img src={study.logoUrl} alt="logo" width="auto" height="100" />
            </Box>
            <Typography variant="body2" className="font-semibold text-2xl sm:text-4xl text-gray-800">
              {study.name}
            </Typography>
          </>
        )}

        {selectedType && (
          <>
            <Breadcrumbs aria-label="breadcrumb" className={breadcrumb} onClick={() => setSelectedType("")}>
              <Typography color="text.main">
                {study.name}
              </Typography>
            </Breadcrumbs>

            {selectedResearchTypeMediaWithExternalLink.length > 0 && (
              <Link
                target="_blank"
                href={selectedResearchTypeMediaWithExternalLink[0].url}
              >
                {selectedType}
              </Link>
            )}

            {selectedResearchTypeMediaWithExternalLink.length === 0 && (
              <Typography
                className={selectedTypeStyle}
              >
                {selectedType}
              </Typography>
            )}

            <Divider component="div" className={dividerStyle} />
          </>

        )}

      </DialogTitle>

      <DialogContent sx={{ padding: 0 }}>
        {renderStudyDetails()}
      </DialogContent>

      <DialogActions sx={{ marginTop: "1rem", paddingRight: 0, paddingBottom: 0 }}>
        {!selectedType && (
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleCLose}
            className={button}
          >
            Close
          </Button>
        )}

        {selectedType && (
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => setSelectedType("")}
            className={button}
          >
            Back
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

StudyDetailsModal.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  studyDetails: PropTypes.object
};

StudyDetailsModal.defaultProps = {
  isOpened: false,
};

export default StudyDetailsModal;