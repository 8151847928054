import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    cursor: "pointer",
  },
  stepTextGray: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "1.8rem",
    fontWeight: 700,
    color: theme.palette.pattern.stepTextGray
  },
  stepTextGreen: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "1.8rem",
    fontWeight: 700,
    color: theme.palette.pattern.greenOld
  },
  dividerGray: {
    width: "160px",
    borderRadius: "4px",
    height: "4px",
    border: "none",
    marginBottom: "3.2rem",
    backgroundColor: theme.palette.pattern.enrollmentDividerGray,
    [theme.breakpoints.down("xl")]: {
      marginBottom: 0
    }
  },
  dividerGreen: {
    width: "160px",
    borderRadius: "4px",
    height: "4px",
    border: "none",
    marginBottom: "3.2rem",
    backgroundColor: theme.palette.pattern.greenOld,
    [theme.breakpoints.down("xl")]: {
      marginBottom: 0
    }
  }
}));

export default useStyles;