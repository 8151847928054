import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.pattern.white,
  },
  nonLandingPage: {
    backgroundColor: theme.palette.pattern.blue
  },
  logo: {
    width: "125px",
    "& img": {
      display: "block",
      margin: "auto"
    }
  },
  btnWhyDonate: {
    color: theme.palette.pattern.yellow
  },
  btnLogin: {
    width: "9rem"
  }
}));

export default useStyles;