import { useAppLocation, useUser } from "../../hooks";
import { useEffect } from "react";

const AuthGuard = ({ children }) => {

  const { sessionPersonalData } = useUser();
  const { isLogin, isSuccessPage, isDashboardPage, navigateTo, pathname } = useAppLocation();

  useEffect(() => {
    if ((isSuccessPage || isDashboardPage) && !sessionPersonalData) {
      navigateTo("/");
    }
    if (isLogin && sessionPersonalData) {
      navigateTo("/");
    }
  }, [pathname]);
  return children;
};

export default AuthGuard;