import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3.5rem",
    fontWeight: 500,
    color: theme.palette.pattern.blue,
  },
  paragraph: {
    fontSize: "1.8rem",
    fontWeight: 400,
    color: theme.palette.pattern.grayNew,
  },
  label: {
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  button: {
    width: "auto",
    height: "61px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.8rem",
    textTransform: "none"
  },
  formWidth: {
    width: "80%"
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  dividerStyle: {
    backgroundColor: theme.palette.pattern.dividerGray,
    borderBottomColor: theme.palette.pattern.dividerGray,
    height: "2px",
    width: "100%"
  },

}));

export default useStyles;