import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "2rem",
    fontWeight: 400,
    color: theme.palette.pattern.blue,
    [theme.breakpoints.up('md')]: {
      fontSize: "3.4rem",
    },
  },
  subTitle: {
    fontSize: "1.6rem",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.pattern.gray,
    [theme.breakpoints.up('md')]: {
      fontSize: "2.4rem",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  paragraph: {
    fontSize: "1.4rem",
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: "2rem",
    },
  },
  button: {
    width: "100%",
    maxWidth: "369px",
    height: "50px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.4rem",
    textTransform: "none",
    [theme.breakpoints.up('md')]: {
      height: "61px",
      fontSize: "1.8rem",
    },
  }
}));

export default useStyles;