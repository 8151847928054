import {
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { useStyles } from "./index";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";

const HeaderMenuMobile = ({
  isRoot,
  isEnrollmentPage,
  isFaqPage,
  isAboutPage,
  isWhyDonatePage,
  isSuccessPage,
  sessionPersonalData,
  onLogout
}) => {

  const {
    root,
    rootNonLandingPage,
    burger,
    burgerNonLandingPage,
    btn,
    btnNonLandingPage,
  } = useStyles();

  const [state, setState] = useState({
    top: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    toggleDrawer("top", false);
    onLogout();
  };

  const renderLoginAndLogoutButtons = () => {
    if (sessionPersonalData) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={btnNonLandingPage}
          onClick={handleLogout}
        >
          Logout
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          className={btnNonLandingPage}
          component={RouterLink}
          to="/login"
        >
          Login
        </Button>
      );
    }
  };

  return (
    <div>
      <IconButton
        onClick={toggleDrawer("top", true)}
        color="primary"
        className={isRoot ? burger : burgerNonLandingPage}
        size="large">
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        className={isRoot ? root : rootNonLandingPage}
        variant="temporary"
        anchor="top"
        open={state["top"]}
        onClose={toggleDrawer("top", false)}
        PaperProps={{
          sx: isRoot ? "" : { backgroundColor: "#1F6EA3" }
        }}
      >
        {(
          <>
            {!isWhyDonatePage &&
              <Button
                className={isRoot ? btn : btnNonLandingPage}
                fullWidth
                disableElevation
                onClick={toggleDrawer("top", false)}
                component={RouterLink}
                to="/whyDonate"
              >
                Why donate tissue?
              </Button>
            }

            {!isAboutPage &&
              <Button
                className={isRoot ? btn : btnNonLandingPage}
                fullWidth
                disableElevation
                onClick={toggleDrawer("top", false)}
                component={RouterLink}
                to="/about"
              >
                About
              </Button>
            }

            {!isFaqPage &&
              <Button
                className={isRoot ? btn : btnNonLandingPage}
                fullWidth
                disableElevation
                onClick={toggleDrawer("top", false)}
                component={RouterLink}
                to="/faq"
              >
                FAQ
              </Button>
            }
          </>
        )}

        {(!isEnrollmentPage || !isSuccessPage) && renderLoginAndLogoutButtons()}

      </Drawer>
    </div>
  );
};

HeaderMenuMobile.propTypes = {
  isRoot: PropTypes.bool.isRequired,
  isDashboardPage: PropTypes.bool.isRequired,
  isEnrollmentPage: PropTypes.bool.isRequired,
  isFaqPage: PropTypes.bool.isRequired,
  isAboutPage: PropTypes.bool.isRequired,
  isWhyDonatePage: PropTypes.bool.isRequired,
  isSuccessPage: PropTypes.bool.isRequired,
  sessionPersonalData: PropTypes.object,
  onLogout: PropTypes.func
};

HeaderMenuMobile.defaultTypes = {
  isRoot: false,
  isDashboardPage: false,
  isEnrollmentPage: true,
  isFaqPage: false,
  isAboutPage: false,
  isWhyDonatePage: false,
  isSuccessPage: false,
  sessionPersonalData: null
};

export default HeaderMenuMobile;