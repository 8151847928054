import * as PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SuccessCard, useStyles } from "./index";

const SuccessView = ({ consent }) => {
  const {
    button
  } = useStyles();

  return (
    <Grid
      item
      container
      justifyContent="center"
    >
      <Grid
        item
        container
        xs={11}
        md={8}
      >
        <SuccessCard consent={consent} />
      </Grid>
      <Grid item container justifyContent="flex-end" mt={5} mb={5} xs={8}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/"
          className={button}
        >
          Return Home
        </Button>
      </Grid>
    </Grid>
  );
};

SuccessView.propTypes = {
  consent: PropTypes.object
};

export default SuccessView;