import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentEnrollmentStep: 0,
  maxEnrollmentStep: 0,
  isNonEligibleOpen: false,
  isScreening: true,
};

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    setEnrollmentStep(state, action) {
      state.currentEnrollmentStep = action.payload.currentEnrollmentStep;
      if (action.payload.maxEnrollmentStep) {
        state.maxEnrollmentStep = action.payload.maxEnrollmentStep;
      }
    },
    toggleNonEligibleDialog(state, action) {
      state.isNonEligibleOpen = action.payload;
    },
    toggleVisibilityScreening(state, action) {
      state.isScreening = action.payload;
    },
    resetEnrollmentState() {
      return initialState;
    }
  }
});

export const {
  setEnrollmentStep,
  toggleNonEligibleDialog,
  toggleVisibilityScreening,
  resetEnrollmentState
} = enrollmentSlice.actions;
export default enrollmentSlice.reducer;