import { createSelector } from "@reduxjs/toolkit";

const studyInfoSelector = state => state.studyInfo;

export const selectStudyInfoByCancer = createSelector(
  studyInfoSelector,
  studyInfo => studyInfo.studyInfoByCancer
);

export const selectStudyInfoStatus = createSelector(
  studyInfoSelector,
  studyInfo => studyInfo.status
);

export const selectAllStudies = createSelector(
  studyInfoSelector,
  studyInfo => studyInfo.allStudies
);

export const selectSelectedStudyPrograms = createSelector(
  studyInfoSelector,
  studyInfo => studyInfo.selectedStudyPrograms
);

export const selectConsentedStudyPrograms = createSelector(
  studyInfoSelector,
  studyInfo => studyInfo.consentedStudyPrograms
);