export const americanStates = [
  "AL", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "ID", "IL", "IN", "IA",
  "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH",
  "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX",
  "UT", "VT", "VA", "WA", "WV", "WI", "WY"
].sort();

export const canadianProvinces = ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"].sort();

export const getAllStatesAndProvinces = () => {
  return americanStates.concat(canadianProvinces).sort();
};

export const races = [
  { text: "American Indian or Alaska Native", disabled: false },
  { text: "Asian", disabled: false },
  { text: "Black or African American", disabled: false },
  { text: "Hispanic, Latino or Spanish Origin", disabled: false },
  { text: "Middle Eastern or North African", disabled: false },
  { text: "Native Hawaiian or other Pacific Islander", disabled: false },
  { text: "Prefer not to answer", disabled: false },
  { text: "Some other race, ethnicity or origin", disabled: false },
  { text: "White", disabled: false }
];

export const stages = [
  { "id": 1, "display": "Stage I" },
  { "id": 2, "display": "Stage II" },
  { "id": 3, "display": "Stage III" },
  { "id": 4, "display": "Stage IV"}];
