import { GreenPanelStepsDesktop, GreenPanelStepsMobile, useStyles } from "./index";
import { Grid, useMediaQuery } from "@mui/material";

import CompleteConsent from "assets/images/elements/3.svg";
import JoinPattern from "assets/images/elements/1.svg";
import LearnResearch from "assets/images/elements/2.svg";
import ProvideInfo from "assets/images/elements/4.svg";

const GreenPanelSteps = () => {

  const classes = useStyles();
  const breakpoint = useMediaQuery(theme => theme.breakpoints.down("md"));

  const stepBubbles = [
    {
      src: JoinPattern,
      alt: "join-pattern",
      text: "1) Join Pattern.org and provide basic information"
    },
    {
      src: LearnResearch,
      alt: "learn-research",
      text: "2) Learn about a research study"
    },
    {
      src: CompleteConsent,
      alt: "complete-consent",
      text: "3) Complete the informed consent"
    },
    {
      src: ProvideInfo,
      alt: "provide-info",
      text: "4) Provide information about your procedure"
    }
  ];

  return (
    <Grid
      className={classes.root}
      item
      container
      justifyContent="center"
      alignItems="center"
    >
      {
        breakpoint ?
          <GreenPanelStepsMobile classes={classes} stepBubbles={stepBubbles} />
          : <GreenPanelStepsDesktop classes={classes} stepBubbles={stepBubbles}/>
      }
    </Grid>
  );
};

export default GreenPanelSteps;




