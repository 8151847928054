const { makeStyles } = require("@mui/styles");
const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: "Poppins",
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  title: {
    fontSize: "3.5rem",
    fontWeight: 500,
    color: theme.palette.pattern.blue,
  },
  formWidth: {
    width: "80%"
  },
  paragraph: {
    fontSize: "1.8rem",
    fontWeight: 400,
    color: theme.palette.pattern.grayNew,
  },
  label: {
    fontSize: "1.5rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  radioGroup: {
    justifyContent: "flex-start",
    "& .MuiFormControlLabel-root span": {
      fontSize: "1.8rem",
      color: "#000000"
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.pattern.grayNew,
      height: 30,
      width: 30,
    }
  },
  button: {
    width: "369px",
    height: "61px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.8rem",
    textTransform: "none"
  }
}));

export { useStyles };