import { makeStyles } from "@mui/styles";
import Image from "assets/images/backgrounds/material-backgrounds/banner-lantern-boy.jpg";

const useStyles = makeStyles({
  root: {
    position: "relative",
    height: "100vh",
    background: `url(${Image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  welcomingCard: {
    position: "absolute",
    margin: "auto",
    top: "2rem",
    left: 0,
    bottom: 0,
    right: 0
  }
});

export default useStyles;