import { Box, Grid, Paper, Typography } from "@mui/material";
import { useStyles } from "./index";

const WhitePanel = () => {
  //todo: slowly transition to the new design using tailwindcss
  return (
    <div className="w-5/6 mx-auto" id="whyDonate" >
      <div className="px-4 md:px-8 pt-20 text-gray-600 ">
        <div className="w-full mb-10">
          <h1 className="text-3xl md:text-6xl font-semibold text-center ">
            Tissue donation drives cancer research
          </h1>
        </div>
        <div className="w-full mb-2 lg:mb-20">
          <p className="text-xl md:text-3xl text-left">
            By contributing your tumor tissue through a surgery, biopsy, or fluid drain you make it possible
            for researchers to make a working copy of your cancer type that will continually grow in a laboratory.
            This copy is called a &quot;model&quot; and it makes it easy for researchers to study your cancer
            type and test treatment ideas. Simply put, by sharing your tumor tissue, you make it possible
            for researchers to make faster progress on critical cancer research.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhitePanel;