import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  logoContainer: {
    width: "140px",
    height: "135px",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    objectFit: "scale-down",
  },
  textContainer: {
    padding: 0,
    height: "200px",
    width: "auto",
    overflow: "scroll",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 6, /* Number of lines to show */
    "-webkit-box-orient": "vertical",
    "& ::after": {
      content: "...", /* Ellipsis */
      display: "block",
      position: "relative",
      top: "-1em",
      left: "100%",
      width: "1em",
      height: "1em",
      marginTop: "-1em",
      marginLeft: "-1em",
      backgroundColor: "white"
    }
  },
  // subtitleStyle: {
  //   fontWeight: 400,
  //   width: "317px",
  //   fontSize: "1.5rem",
  //   color: theme.palette.pattern.grayNew
  // },
  titleStyle: {
    fontWeight: 700,
    fontSize: "1.8rem",
    color: theme.palette.pattern.grayTitle,
  },
  descriptionStyle: {
    fontWeight: 400,
    fontSize: "1.5rem",
    color: theme.palette.pattern.grayNew
  },
  button: {
    width: "173px",
    height: "37px",
    fontWeight: 600,
    fontSize: "1.5rem",
    backgroundColor: theme.palette.pattern.white,
    color: theme.palette.pattern.detailsBtn,
    border: "1px solid #1694CF",
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.pattern.white,
      color: theme.palette.pattern.consentBorder,
      borderColor: theme.palette.pattern.consentBorder
    },
  }
}));