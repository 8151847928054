import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const PatternMultilineTextField = ({
  name,
  label,
  control,
  helperText,
  required,
  error,
  ...props
}) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      control={control}
      multiline
      rows={4}
      error={!!error}
      required={required}
      helperText={error ? helperText : ""}
      {...props}
    />
  );
};

PatternMultilineTextField.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.any,
  required: PropTypes.bool,
};


export default PatternMultilineTextField;