import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { ProgramsCarousel } from "./ProgramsCarousel";
import { StudyDetailsModal } from "../StudyDetailsModal";
import { splitArrayIntoChunks } from "shared/utils/array";
import { useState } from "react";
import { useStyles } from "./index";

// eslint-disable-next-line react/prop-types
const CurrentPrograms = ({ studies }) => {
  const { title, paragraph } = useStyles();
  const [open, setOpen] = useState(false);
  const [studyDetails, setStudyDetails] = useState(null);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isScreenMediumSize = useMediaQuery(theme => theme.breakpoints.down("md"));
  const isScreenLargeSize = useMediaQuery(theme => theme.breakpoints.down("lg"));
  const getNumberOfItems = () => {
    if (isMobile) {
      return 1;
    } else if (isScreenMediumSize) {
      return 2;
    } else if (isScreenLargeSize) {
      return 3;
    }
    else {
      return 3;
    }
  };

  const studiesInChunks = splitArrayIntoChunks(studies, getNumberOfItems());

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewDetails = (study) => {
    setStudyDetails(study);
    handleOpen();
  };

  return (
    <Grid>
      <Grid item>
        <Typography className={`${title} text-gray-600`}>Current Programs</Typography>
      </Grid>
      <Grid item>
        <Typography className={paragraph}>
          Get started to be matched with a program for your cancer type
        </Typography>
      </Grid>
      <Grid item>
        <Box mt={5} mb={16}>
          <ProgramsCarousel
            studiesInChunks={studiesInChunks}
            onViewDetails={(study) => handleViewDetails(study)}
          />
        </Box>
      </Grid>
      <StudyDetailsModal onClose={handleClose} isOpened={open} studyDetails={studyDetails} />
    </Grid>
  );
};

export default CurrentPrograms;
