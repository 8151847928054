import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    fontFamily: "Plus Jakarta Sans",
    color : theme.palette.primary.main,
  },
  buttonLinkLandingPage: {
    fontFamily: "Plus Jakarta Sans",
    color: theme.palette.primary.blue,
    fontWeight: 500,
    fontSize: "1.8rem",
    textTransform: "none"
  },
  buttonLinkNonLandingPage: {
    fontFamily: "Plus Jakarta Sans",
    color: "#ffffff",
    fontWeight: 500,
    fontSize: "1.8rem",
    textTransform: "none"
  },
  btnLogin: {
    fontFamily: "Plus Jakarta Sans",
    backgroundColor : theme.palette.primary.main,
    width: "9rem",
    textTransform: "none"
  },
  btnNonLandingPage: {
    fontFamily: "Plus Jakarta Sans",
    backgroundColor: "#ffffff",
    color: "#000000",
    width: "9rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3"
    }
  },
}));

export default useStyles;