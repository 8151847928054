
import { CurrentPrograms } from "./CurrentPrograms";
import { useEffect } from "react";
import { useEnrollment } from "../../../shared/hooks";



const WhitePanelStudies = () => {
  const { fetchAllStudies, allStudies } = useEnrollment();
  useEffect(() => {
    fetchAllStudies(); // Fetch studies when the component mounts
  }, [fetchAllStudies]);


  //todo: slowly transition to the new design using tailwindcss
  return (
    <div className="w-5/6 mx-auto" id="whyDonate" >
      <div className="px-4 md:px-8 pt-5 text-gray-600 ">
        <div className="w-full">
          <h1 className="text-3xl md:text-6xl font-semibold text-center ">

          </h1>
        </div>
        <CurrentPrograms studies={allStudies} />
      </div>
    </div>
  );
};

export default WhitePanelStudies;