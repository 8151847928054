import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    "& div.MuiSelect-outlined": {
      height: "auto",
      // paddingTop: "13.5px",
      // paddingBottom: "13.5px",
      display: "flex",
      alignItems: "center",
      wordBreak: "break-word",
    },
    "& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      fontSize: "1.8rem",
      fontWeight: 500,
      alignItems: "center",
      "& .MuiFormHelperText-root": {
        fontSize: "1.8rem",
        fontWeight: 500,
        color: "red !important"
      }
    }
  }
}));


const PatternMultiSelect = ({
  name,
  label,
  control,
  options,
  testId,
  onSelect,
  ...props
}) => {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    variant: "menu"
  };

  const handleSelect = (value, name) => onSelect(value, name);

  const { root } = useStyles();

  return (
    <>
      <FormControl {...props}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, name } }) => {
            return (
              <TextField
                variant="outlined"
                label={label}
                select
                multiline
                rows={5}
                className={root}
                inputProps={{ style: { resize: "both" } }}
                SelectProps={{
                  multiple: true,
                  value: value,
                  renderValue: (selected) => selected.join(", "),
                  onChange: e => handleSelect(e.target.value, name),
                  MenuProps: MenuProps,
                  SelectDisplayProps: {
                    "data-testid": `${testId}`
                  }
                }}
              >
                {options.length && options.map((option, index) => (
                  <MenuItem key={index} value={option.text} disabled={option.disabled}>
                    <Checkbox color="primary" checked={!option.disabled && value.includes(option.text)} />
                    <ListItemText primary={option.text} />
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />
      </FormControl>
    </>
  );
};

PatternMultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.any,
  control: PropTypes.any,
  helperText: PropTypes.string,
  children: PropTypes.node,
  testId: PropTypes.string,
  onSelect: PropTypes.func
};

export default PatternMultiSelect;