import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useStyles } from "./PatternRadioQuestion.styles";
import PropTypes from "prop-types";

export const PatternRadioQuestion = ({ top, control, title, field, testId, isRequired = true }) => {

  const {
    text,
    radioGroup
  } = useStyles();

  return (
    <Grid item container alignItems="center">
      <Grid item xs={12} md={10}>
        <Box mt={top} mb={1}>
          <Typography className={text} variant="subtitle1" align="flex-start">
            {title}{isRequired && <span title="Required field">&#42;</span>}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={6} alignItems="flex-start">
        <Controller
          name={field}
          isClearable
          control={control}
          render={({ field }) =>
            <RadioGroup
              aria-label={field}
              name={field}
              row
              className={radioGroup}
              {...field}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    color="primary"
                    inputProps={{
                      "data-testid": `${testId}-yes`,
                    }}
                  />}
                label="Yes"
                sx={{ mr: 20 }}
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    inputProps={{
                      "data-testid": `${testId}-no`
                    }}
                    color="primary"
                  />}
                label="No"
              />
            </RadioGroup>
          }
        />
      </Grid>
    </Grid>
  );
};

PatternRadioQuestion.propTypes = {
  isRequired: PropTypes.bool,
  top: PropTypes.number,
  control: PropTypes.object,
  title: PropTypes.string,
  field: PropTypes.string,
  testId: PropTypes.string
};