import * as PropTypes from "prop-types";
import { Box, Button, Grid } from "@mui/material";
import { useStyles } from "./index";
import HelpIcon from "@mui/icons-material/Help";

const AskQuestion = ({ onOpenHelp }) => {


  const { btnWrapper, consentHelpBtn } = useStyles();

  return (
    <Grid
      item
      container
      justifyContent="space-around"
      className={btnWrapper}
      alignItems="center"
    >
      <Grid item>
        <Box mb={2}>
          <Button
            className={consentHelpBtn}
            variant="contained"
            startIcon={<HelpIcon fontSize="small"/>}
            onClick={onOpenHelp}
          >
            I have a question
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

AskQuestion.propTypes = {
  onOpenHelp: PropTypes.func
};

export default AskQuestion;