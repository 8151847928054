import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    textAlign: "center",
  },
  title: {
    fontSize: "3.4rem",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      marginBottom: theme.spacing(1),
    }
  },
  paragraph: {
    fontSize: "2.4rem",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.2rem",
      marginBottom: theme.spacing(1),
    }
  },
  button: {
    width: "auto",
    height: "50px",
    fontWeight: 500,
    fontSize: "2.1rem",
    backgroundColor: theme.palette.pattern.white,
    color: theme.palette.primary.main,
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.pattern.white,
      color: theme.palette.pattern.consentBorder,
      borderColor: theme.palette.pattern.consentBorder
    },
  }
}));

export default useStyles;