import { Grid, Typography } from "@mui/material";
import Ellipse from "assets/images/elements/Ellipse.svg";
import PropTypes from "prop-types";
const GreenPanelStepsDesktop = ({ classes, stepBubbles }) => {
  const { avatar, avatarSubtitle } = classes;

  return (
    <>


      <div
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 w-5/6 mx-auto">
          {stepBubbles.map(({ text, src, alt }, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="w-48 h-48 bg-white rounded-full flex items-center justify-center mb-4">
                <img
                  src={src}
                  alt={alt}
                  className="w-42 h-42 object-contain"
                />
              </div>
              <div className="text-white font-bold text-lg sm:text-xl md:text-4xl text-center mt-10">
                {text}
              </div>
            </div>
          ))}
        </div>

      </div >
    </>
  );
};

GreenPanelStepsDesktop.propTypes = {
  classes: PropTypes.object,
  stepBubbles: PropTypes.array
};

GreenPanelStepsDesktop.defaultProps = {
  classes: null,
  stepBubbles: null
};

export default GreenPanelStepsDesktop;