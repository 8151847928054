import { Box, Chip } from "@mui/material";
import { PatternTextField } from "shared/components/PatternTextField";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import OutsideClickHandler from "react-outside-click-handler";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    minHeight: "70px",
    gap: "8px",
    width: "auto",
    flexWrap: "wrap",
    flexDirection: "row",
    border: "1px solid lightgray",
    padding: 4,
    borderRadius: "4px",
    "& > div.container": {
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    "& > div.container > span": {
      backgroundColor: "gray",
      padding: "1px 3px",
      borderRadius: "4px"
    }
  },
  placeholder: {
    marginLeft: "8px",
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#C4C4C4",
    [theme.breakpoints.down("xl")]: {
      fontSize: "12px"
    }
  }
}));

const PatternMultiChipTextInput = ({onChange}) => {
  const { root, placeholder} = useStyles();
  const [ values, setValues ] = useState([]);
  const [ currValue, setCurrValue ] = useState("");
  const [ showInput, setShowInput ] = useState(false);

  const handleKeyUp = (e) => {
    if (!e.target.value) return;
    if (e.keyCode === 188) {
      setValues((oldState) => [...oldState, e.target.value.replace(/,/g, "")]);
      setCurrValue("");
    }
  };

  useEffect(() => {
    onChange(values);
  }, [values]);

  const handleChange = (e) => {
    setCurrValue(e.target.value.replace(/,/g, ""));
  };

  const handleDelete = ( item, index) =>{
    let arr = [...values];
    arr.splice(index,1);
    setValues(arr);
  };

  const handleFocus = () => {
    setShowInput(true);
  };

  const handleClickOutside = () => {
    if (values.length > 0) return;
    setShowInput(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <FormControl
        className={root}
        tabIndex="0"
        onFocus={handleFocus}
      >
        {showInput && (
          <>
            <Box className="container">
              {values.map((item, index) => {
                if (!item) return;
                return (
                  <Chip
                    key={index}
                    color="primary"
                    size="medium"
                    onDelete={() => handleDelete(item, index)} label={item}
                  />
                );
              })}
            </Box>

            <PatternTextField
              style={{height: "60px"}}
              value={currValue}
              variant="standard"
              onChange={handleChange}
              onKeyDown={handleKeyUp}
            />
          </>
        )}

        {!showInput && values.length === 0 &&
          <span className={placeholder}>Separate each therapy by a comma,
        if you haven&#39;t received any prior
        treatments or therapies you can skip this field</span>}
      </FormControl>
    </OutsideClickHandler>
  );
};

PatternMultiChipTextInput.propTypes = {
  onChange: PropTypes.func
};

export default PatternMultiChipTextInput;