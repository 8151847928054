const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => (({
  text: {
    fontFamily: "Poppins",
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  radioGroup: {
    justifyContent: "flex-start",
    "& .MuiFormControlLabel-root span": {
      fontSize: "1.8rem",
      color: "#000000"
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.pattern.grayNew,
      height: 30,
      width: 30,
    }
  }
})));

export { useStyles };