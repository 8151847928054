import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.pattern.white,
  },
  logo: {
    width: "6.8rem"
  },
  paragraph: {
    color: theme.palette.pattern.blue,
    fontSize: "3rem",
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      fontSize: "2.6rem"
    }
  },
  title: {
    color: theme.palette.pattern.gray,
    fontSize: "3rem",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      marginTop: "5rem"
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "2rem"
    }
  }
}));

export default useStyles;