import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PatternRadioQuestion, PatternSelect, PatternTextField } from "shared/components";
import { PatternRadioQuestionInfoSource } from "./index";
import { defaultScreeningValues, screeningSchema } from "shared/utils/formUtils";
import { i18n } from "shared/constants/i18n";
import { stages } from "shared/constants/arrays";
import { useStyles } from "./YourInfoScreeningForm.styles";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

const YourInfoScreeningForm = ({ onSubmit, cancers }) => {

  const { title, formWidth, label, paragraph, button } = useStyles();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors }
  } = useForm({
    defaultValues: defaultScreeningValues,
    mode: "all",
    resolver: yupResolver(screeningSchema),
    shouldUnregister: true
  });

  const tissueExists = watch("tissueExists");
  const notifyOfOpportunities = watch("notify_of_opportunities");

  return (
    <>

      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        xs={12}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
          className={formWidth}
        >
          <Paper
            elevation={3}
            sx={{
              padding: "60px",
              marginTop: "50px",
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: "40px"
            }}>

            <Grid item container direction="column" alignItems="center" justifyContent="center">
              <Grid item>
                <Typography variant="h1" className={title} align="center" mb={3}>
                  Your Information
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h3" align="center" className={paragraph} mb={6}>
                  {i18n.enrollment.eligibilityForm.yourInfoFirstTitle}
                </Typography>
              </Grid>

              <Grid item container justifyContent="space-between" spacing={3}>
                {/*First Name*/}

                <Grid item xs={12} lg={4}>
                  <Typography className={label} mb={1} mt={0}>
                    First Name<span title="Required field">&#42;</span>
                  </Typography>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field, fieldState: { error } }) =>
                      <PatternTextField
                        variant="outlined"
                        color="primary"
                        error={!!error}
                        fullWidth
                        helperText={error ? error.message : ""}
                        required
                        {...field}
                      />
                    }
                  />
                </Grid>

                {/*Last Name*/}
                <Grid item xs={12} lg={4}>
                  <Typography className={label} mb={1} mt={0}>Last Name<span
                    title="Required field">&#42;</span></Typography>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field, fieldState: { error } }) =>
                      <PatternTextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : ""}
                        required
                        {...field}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Typography className={label} mb={1} mt={0}>Email<span
                    title="Required field">&#42;</span></Typography>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) =>
                      <PatternTextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        inputProps={{
                          minLength: 1,
                          maxLength: 100,
                          type: "email",
                          "data-testid": "email",
                        }}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        required
                        {...field}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >

                <Grid item container justifyContent="flex-start">
                  <PatternRadioQuestion
                    testId="tissue-exists"
                    top={5}
                    control={control}
                    field="tissueExists"
                    title={i18n.enrollment.screeningForm.tissueExists}
                  />
                </Grid>

                {tissueExists === "Yes" && (
                  <Grid item container justifyContent="flex-start">
                    <PatternRadioQuestionInfoSource
                      top={5}
                      control={control}
                      field="hear_about_pattern"
                      title={i18n.enrollment.screeningForm.howDidYouHear}
                    />
                  </Grid>
                )}

                {tissueExists === "No" && (
                  <>
                    <Grid item container justifyContent="flex-start">
                      <Typography className={label} mb={3} mt={3}>
                        {i18n.enrollment.screeningForm.noTissueText1}
                      </Typography>
                      <Typography className={label} mb={3}>
                        {i18n.enrollment.screeningForm.noTissueText2}
                      </Typography>
                      <PatternRadioQuestion
                        testId="notify_of_opportunities"
                        control={control}
                        field="notify_of_opportunities"
                        title={i18n.enrollment.screeningForm.notifyUs}
                      />
                    </Grid>

                    {notifyOfOpportunities === "Yes" && (
                      <Grid item container spacing={3}>
                        <Grid item xs={8}>
                          <Typography className={label} mb={1} mt={3}>Cancer Diagnosis<span
                            title="Required field">&#42;</span></Typography>
                          <PatternSelect
                            control={control}
                            name="cancer_diagnosis"
                            error={!!errors.cancer_diagnosis}
                            helperText={errors.cancer_diagnosis ? "You must select a diagnosis." : ""}
                            value={cancers}
                            fullWidth
                            testId="cancer-diagnosis"
                          >
                            {cancers.map(
                              cancer =>
                                <MenuItem
                                  key={cancer.id}
                                  value={cancer.id}
                                >
                                  {cancer.display}
                                </MenuItem>)}
                          </PatternSelect>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography className={label} mb={1} mt={3}>Stage<span
                            title="Required field">&#42;</span></Typography>
                          <PatternSelect
                            control={control}
                            name="cancer_stage"
                            value={stages}
                            fullWidth
                            testId="cancer-stage"
                          >
                            {stages.map(
                              stage =>
                                <MenuItem
                                  key={stage.id}
                                  value={stage.id}
                                >
                                  {stage.display}
                                </MenuItem>)}
                          </PatternSelect>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>

          {tissueExists === "Yes" && (
            <Grid item container justifyContent="flex-end" mb={5}>
              <Button
                className={button}
                data-testid="save-button"
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid}
                sx={{ textTransform: "none" }}
              >
                Save and Continue
              </Button>
            </Grid>
          )}

          {tissueExists === "No" && (
            <Grid item container justifyContent="flex-end" mb={5}>
              <Button
                className={button}
                data-testid="save-button"
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid}
              >
                Submit
              </Button>
            </Grid>
          )}

        </form>
      </Grid>
    </>
  );
};

YourInfoScreeningForm.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  cancers: PropTypes.array
};

export default YourInfoScreeningForm;