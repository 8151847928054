import { GET_CONSENT, POST_CONSENT } from "shared/constants/endpoints";
import axios from "axios";

export class ConsentService {
  static async getConsent(study_id) {
    try {
      const res = await axios.get(GET_CONSENT, { params: { study_id } });
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  static async postConsent(consentId) {
    const consent_form_ids = [consentId];
    try {
      const res = await axios.post(POST_CONSENT, {
        consent_form_ids,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}