import * as PropTypes from "prop-types";
import {
  Button,
  Divider,
  Grid, Paper,
  Typography
} from "@mui/material";
import { MatchedPrograms } from "./MatchedPrograms";
import { StudyDetailsModal } from "components/LandingHome/WhitePanel/StudyDetailsModal";
import { useState } from "react";
import { useStyles } from "./index";
import { useUser } from "shared/hooks";

const StudyInfoView = ({
  cancerName,
  studyInfoByCancer,
  selectedStudyPrograms,
  setSingleStudyProgram,
  setMultipleStudyPrograms,
  onClick
}) => {

  const { title, paragraph, dividerStyle, button } = useStyles();
  const [open, setOpen] = useState(false);
  const [studyDetails, setStudyDetails] = useState(null);
  const { sessionPersonalData } = useUser();

  const donatesToMultiple = sessionPersonalData?.profile.donate_to_multiple;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleViewDetails = (study) => {
    setStudyDetails(study);
    handleOpen();
  };

  const handleSelectProgram = (programId) => {
    donatesToMultiple ?
      setMultipleStudyPrograms(programId) :
      setSingleStudyProgram(programId);
  };

  const handleBeginConsent = () => {
    onClick(selectedStudyPrograms);
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      xs
    >
      <Grid item container justifyContent="center">
        <Paper
          elevation={3}
          sx={{
            padding: "80px",
            marginTop: "50px",
            marginBottom: "40px",
            width: "80%"
          }}
        >
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h1" className={title} align="center">
                Study Information for
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h1" className={title} align="center" mt={3} mb={3}>
                {cancerName}
              </Typography>
            </Grid>
            <Grid item>
              {studyInfoByCancer.length > 0 && (
                <Typography className={paragraph} align="center">
                  Based on the information you provided,
                  Pattern.org has matched you with the following possible studies.
                  Bellow you can learn more about the scientific teams and their goals.
                </Typography>
              )}
            </Grid>

            <Grid item container mt={6} mb={6}>
              <Divider component="div" className={dividerStyle} />
            </Grid>

            {/*{ studyInfo.length === 0 && (*/}
            {/*  <Box mt={4} mb={2}>*/}
            {/*    <Button*/}
            {/*      data-testid={"visit-account"}*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      component={RouterLink}*/}
            {/*      to="/dashboard"*/}
            {/*    >*/}
            {/*      Visit account*/}
            {/*    </Button>*/}
            {/*  </Box>*/}
            {/*)}*/}

            {studyInfoByCancer.length > 0 && (
              <MatchedPrograms
                selectedProgramIds={selectedStudyPrograms}
                matchedPrograms={studyInfoByCancer}
                onViewDetails={handleViewDetails}
                onSelectProgram={handleSelectProgram}
              />
            )}

          </Grid>
        </Paper>
        <Grid
          item
          container
          justifyContent="flex-end"
          mb={4}
          sx={{ width: "80%" }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={selectedStudyPrograms.length === 0}
            className={button}
            onClick={handleBeginConsent}
          >
            Begin Consent
          </Button>
        </Grid>
      </Grid>

      <StudyDetailsModal
        onClose={handleClose}
        isOpened={open}
        studyDetails={studyDetails}
      />
    </Grid>
  );
};

StudyInfoView.propTypes = {
  selectedStudyPrograms: PropTypes.array,
  setSingleStudyProgram: PropTypes.func,
  setMultipleStudyPrograms: PropTypes.func,
  cancerName: PropTypes.string,
  studyInfoByCancer: PropTypes.any,
  onClick: PropTypes.func
};

export default StudyInfoView;