import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  studyInfoByCancer: [],
  allStudies: [],
  selectedStudyPrograms: [],
  consentedStudyPrograms: [],
  status: "idle",
  error: null
};

const studyInfoSlice = createSlice({
  name: "studyInfo",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    getStudyInfoByCancer(action) {},
    // eslint-disable-next-line no-unused-vars
    getAllStudies(action) {},
    setMultipleStudyProgramIds(state, action) {
      const selectedStudyIds = action.payload;
      if (state.selectedStudyPrograms.includes(selectedStudyIds)) {
        state.selectedStudyPrograms = state.selectedStudyPrograms.filter(id => id !== selectedStudyIds);
      } else {
        state.selectedStudyPrograms = [...state.selectedStudyPrograms, selectedStudyIds];
      }
    },
    setSingleStudyProgramId(state, action) {
      state.selectedStudyPrograms = [action.payload];
    },
    pending(state) {
      state.status = "loading";
    },
    successGetStudyInfoByCancer(state, action) {
      state.status = "succeeded";
      state.studyInfoByCancer = action.payload;
      state.error = null;
    },
    successGetAllStudies(state, action) {
      state.status = "succeeded";
      state.allStudies = action.payload;
      state.error = null;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  setMultipleStudyProgramIds,
  setSingleStudyProgramId,
  getStudyInfoByCancer,
  getAllStudies,
  successGetStudyInfoByCancer,
  successGetAllStudies,
  pending,
  success,
  failure
} = studyInfoSlice.actions;
export default studyInfoSlice.reducer;