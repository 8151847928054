import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

const PatternTextField = withStyles({
  root: {
    "& label, & span, & p": {
      fontWeight: 500,
      // fontSize: "2rem",
    },
    "& .MuiInputBase-root": {
      height: "45px"
    },
    // "& p.Mui-error": {
    //   position: "absolute",
    // }
  },
})(TextField);

export default PatternTextField;