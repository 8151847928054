import { Controller } from "react-hook-form";
import { MASK, PHONE_PLACEHOLDER } from "../../utils/formUtils";
import { PatternTextField } from "../PatternTextField";
import { useState } from "react";
import { useStyles } from "./index";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";

const PatternPhoneField = ({
  control,
  name,
  label,
  helperText,
  setValue,
  required,
  setError,
  clearErrors,
  testId
}) => {

  const [ isFocused, setIsFocused ] = useState(false);

  const { withError } = useStyles();

  const handleOnBlur = (e, name) => {
    const currentValue = e.target.value;
    if (!currentValue || currentValue === PHONE_PLACEHOLDER || currentValue.includes("_")) {
      setValue(name, "");
      setError(name);
      setIsFocused(true);
    }
  };

  const handleOnChange = (e, name) => {
    const currentValue = e.target.value;
    if (currentValue.includes("_")) {
      clearErrors(name);
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
    setValue(name, currentValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={( {
        fieldState: { error},
        field: { value,name
        }}) =>
        <InputMask
          mask={MASK}
          placeholderChar="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
          onChange={e => handleOnChange(e, name)}
          onBlur={e => handleOnBlur(e, name)}
          value={value}
        >
          {() => (
            <PatternTextField
              variant="outlined"
              color="primary"
              className={isFocused ? withError : ""}
              label={label}
              placeholder={PHONE_PLACEHOLDER}
              InputLabelProps={{shrink: true}}
              inputProps={{
                minLength: 10,
                "data-testid": `${testId}`
              }}
              error={!!error}
              required={required}
              helperText={error ? helperText : ""}
              fullWidth
            />
          )}
        </InputMask>
      }
    />
  );
};

PatternPhoneField.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.any,
  setValue: PropTypes.any,
  required: PropTypes.bool,
  setError: PropTypes.any,
  clearErrors: PropTypes.any,
  testId: PropTypes.string
};

export default PatternPhoneField;