import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  rootNonLandingPage: {
    display: "flex",
    "& a": {
      backgroundColor: theme.palette.pattern.blue,
    }
  },
  burger: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  burgerNonLandingPage: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: 0
    },
    "& svg":  {color: "#ffffff"}
  },
  btn: {
    borderRadius: 0,
    color: theme.palette.pattern.blue,
    textTransform: "none"
  },
  btnNonLandingPage: {
    borderRadius: 0,
    color: theme.palette.pattern.white,
    textTransform: "none"
  },
  btnLogin: {
    backgroundColor: theme.palette.pattern.yellow
  }
}));

export default useStyles;