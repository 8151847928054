import { Box, Grid, useMediaQuery } from "@mui/material";
import { FooterLinksDesktop, FooterLinksMobile, useStyles } from "./index";
import PropTypes from "prop-types";

const FooterLinks = ({ showLinks }) => {

  const breakpoint960 = useMediaQuery(theme => theme.breakpoints.down(960));
  const breakpoint600 = useMediaQuery(theme => theme.breakpoints.down(600));

  const { linkWrapper, link } = useStyles();

  if (!showLinks) {
    return null;
  }

  return (
    <Grid item container xs>
      <Grid
        item
        container
        alignItems="center"
        justifyContent={breakpoint960 ? "center" : "flex-start"}
        sm={12}
        md={8}
      >
        <Box ml={breakpoint960 ? 0 : 3} mb={breakpoint960 ? 3 : 0}>
          {breakpoint600 ?
            <FooterLinksMobile classes={{ linkWrapper, link }} /> :
            <FooterLinksDesktop classes={{ linkWrapper, link }} />
          }

        </Box>
      </Grid>
    </Grid>
  );
};

FooterLinks.propTypes = {
  showLinks: PropTypes.bool
};

FooterLinks.defaultProps = {
  showLinks: true
};

export default FooterLinks;