import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "1040px",
      height: "100%",
      borderRadius: "41px",
      paddingLeft: "100px",
      paddingRight: "77px",

      // Media query for small screens
      "@media (max-width: 600px)": {
        paddingLeft: "20px",   // Reduce padding on small screens
        paddingRight: "20px",  // Reduce padding on small screens
        width: "100%",         // Adjust width for small screens if necessary
        borderRadius: "20px",   // Optionally adjust border radius for small screens
      },
    },
  },
  header: {
    marginBottom: "50px",
    fontFamily: "Mulish",
    padding: 0,
    fontSize: "2.5rem",
    fontWeight: 500,
    color: theme.palette.pattern.white
  },
  content: {
    height: "120%",
    padding: 0,
    marginBottom: 0,
    overflowY: "scroll",
    overflow: "hidden",
  },
  exampleUses: {
    fontWeight: 500,
    fontSize: "3rem",
    color: theme.palette.pattern.blue,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  researchTypesContainer: {
    // position: "fixed"
  },
  videoWrapper: {
    position: "relative",
    paddingBottom: "56.25%" /* 16:9 */,
    height: 0,
    overflow: "hidden"
  },
  aspectRatio16x9: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0
  },
  paragraph: {
    height: "100%",
    fontSize: "2rem",
    fontWeight: 400,
    color: theme.palette.pattern.blackDialog,
    paddingRight: "16px"
  },
  chipContainer: {
    "& div:hover": {
      backgroundColor: "rgba(0, 144, 208, 0.6)",
    }
  },
  chip: {
    backgroundColor: "rgba(0, 144, 208, 0.4)",
    color: theme.palette.pattern.newTextBlue,
    borderRadius: "10px",
    width: "285px",
    height: "52px",
    fontSize: "1.8rem",
    fontWeight: 500,
    textAlign: "left",
    textDecoration: "underline",
    "& :hover": {
      cursor: "pointer"
    },
  },
  breadcrumb: {
    "& :hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  selectedTypeStyle: {
    fontFamily: "Mulish",
    fontWeight: 500,
    fontSize: "5rem",
    color: "#080809"
  },
  dividerStyle: {
    backgroundColor: theme.palette.pattern.dividerGray,
    borderBottomColor: theme.palette.pattern.dividerGray,
    height: "9px",
  },
  button: {
    borderRadius: "10px",
    width: "221px",
    height: "46px",
    textTransform: "none",
    fontSize: "1.8rem",
    fontWeight: 700,
    marginBottom: "32px",
  }
}));

export default useStyles;