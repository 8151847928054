import * as yup from "yup";

export const PHONE_PLACEHOLDER = "(___) ___-____";
export const MASK = "(999) 999-9999";

export const screeningSchema = yup.object().shape({
  first_name: yup.string().required("Please enter your name"),
  last_name: yup.string().required("Please enter your last name"),
  email: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .required("Email is required"),
  tissueExists: yup.string().required(),
  hear_about_pattern: yup.string().when("tissueExists", {
    is: "Yes",
    then: () => yup.string().required(),
  }),

  notify_of_opportunities: yup.string().when("tissueExists", ([tissueExists], schema) => {
    if (tissueExists === "No") {
      return schema.required();
    }
    return schema;
  }),
  cancer_diagnosis: yup.string()
    .when(["tissueExists", "notify_of_opportunities"], ([tissueExists, notify_of_opportunities], schema) => {
      if (tissueExists === "No" && notify_of_opportunities === "Yes") {
        return schema.required();
      }
      return schema;
    }),
  cancer_stage: yup.string()
    .when(["tissueExists", "notify_of_opportunities"], ([tissueExists, notify_of_opportunities], schema) => {
      if (tissueExists === "No" && notify_of_opportunities === "Yes") {
        return schema.required();
      }
      return schema;
    })
});

export const personalInfoSchema = yup.object().shape({
  cancer_diagnosis: yup.number().required(),
  cancer_stage: yup.string().required(),
  occurrence_type: yup.string(),
  site: yup.string(),
  tumor_size: yup.number().transform((value) => Number.isNaN(value) ? null : value )
    .nullable(),
  donate_to_multiple: yup.string(),
  previous_treatments: yup.array().of(yup.string()),
  primary_occurrence_location_for_metastatic: yup.string(),
  country: yup.string().required(),
  mailing_address_1: yup.string(),
  mailing_address_2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  phone_number: yup.string().required("Phone number is required"),
  postcode: yup.string()
  // postcode: yup.string().test(postcode => postcode.length === 0 || postcode.length >= 5)
});

export const donationInfoSchema = yup.object().shape({
  surgery_date: yup.string(),
  hospital_name: yup.string().required(),
  hospital_city: yup.string().required(),
  hospital_state: yup.string().required(),
  surgeon: yup.string(),
  surgeon_phone: yup.string(),
  oncologist: yup.string(),
  oncologist_phone: yup.string(),
  birthday: yup.date()
    .min(new Date("01/18/1902"),"Choose a birth date on or after 01/18/1902")
    .max(new Date(), "Choose a birth date on or before today")
    .typeError("Invalid date format. It should be MM/DD/YYYY")
    .required("Your birthdate is required"),
  selected_races: yup.array().of(yup.string()).required(),
  sex: yup.string().required(),
  user_notes: yup.string()
});

export const consentSchema = yup.object().shape({
  fullName: yup.string().required(),
  birthday: yup.date()
    .min(new Date("01/18/1902"),"Choose a birth date on or after 01/18/1902")
    .max(new Date(), "Choose a birth date on or before today")
    .typeError("Invalid date format. It should be MM/DD/YYYY")
    .required("Your birthdate is required")
});

export const contactInfoSchema = yup.object().shape({
  first_name: yup.string().required("Please enter your name"),
  last_name: yup.string().required("Please enter your last name"),
  email: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .required("Email is required")
});

export const loginFormEmailSchema = yup.object().shape({
  email: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .required("Email is required"),
});

export const loginFormPasscodeSchema = yup.object().shape({
  passcode: yup.number()
    .required("You must enter a passcode.")
    .typeError("You must enter a passcode.")
    .test("len", "Must be exactly 6 characters", val => {
      if (val) {
        return val.toString().length === 6;
      }
    })
});

export const defaultContactInfoValues = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
};

export const defaultScreeningValues = {
  first_name: "",
  last_name: "",
  email: "",
  tissueExists: "Yes",
  hear_about_pattern: "",
  notify_of_opportunities: "Yes",
  cancer_diagnosis: "",
  cancer_stage: ""
};

export const defaultPersonalInfoValues = {
  cancer_diagnosis: "",
  cancer_stage: "",
  site: "",
  occurrence_type: "",
  tumor_size: 0,
  donate_to_multiple: "",
  // primary_occurrence_location_for_metastatic: "",
  previous_treatments: "",
  country: "",
  mailing_address_1: "",
  mailing_address_2: "",
  city: "",
  state: "",
  postcode: "",
  phone_number: ""
};

export const defaultDonationInfoValues = {
  surgery_date: "",
  hospital_name: "",
  hospital_city: "",
  hospital_state: "",
  surgeon: "",
  surgeon_phone: "",
  oncologist: "",
  oncologist_phone: "",
  birthday: null,
  selected_races: [],
  sex: "",
  user_notes: ""
};

export const parseNumberToTwoDecimals = (number) => {
  return parseFloat(number).toFixed(2);
};

export const parsePhoneNumber = (unparsed) => {
  if (!unparsed) return;
  const unwantedChars = ["(", ")", "-", " ", "_"];
  return unparsed.split("").filter(char => !unwantedChars.includes(char)).join("");
};

// const validatePhoneNumber = (phoneNumber) => {
//   if (!phoneNumber) return;
//   return parsePhoneNumber(phoneNumber).length === 10;
// };

export const isEnrollmentPersonalInfoFormInvalid = (isValid, cancerDiagnosis, phoneNumber ) => {
  return !isValid ||
      cancerDiagnosis === 0 ||
  // email !== email2 ||
  !phoneNumber ||
  phoneNumber === PHONE_PLACEHOLDER ||
  phoneNumber.includes("_");
};

export const serializeUserData = (userData) => ({
  first_name: userData.first_name,
  last_name: userData.last_name,
  email: userData.email
  // complete: true,
  // qs_params: {},
  // phone_number: parsePhoneNumber(userData.phone_number),
  // cancer_diagnosis: cancers.find(cancer => cancer.id === userData.cancer_diagnosis)
});

export const serializePatchUserData = (userData) => {
  const mainUserData = ["email", "first_name", "last_name"];
  const entries = Object.entries(userData);
  const isMainUserData = (entry) => mainUserData.includes(entry[0]);
  const serialized = entries.map(entry => {
    return { op: "replace", path: `/${isMainUserData(entry[0]) ? "" : "profile/"}${entry[0]}`, value: entry[1] };
  });
  return serialized;
};

export const serializeDonationInfo = (donationInfo, id) => ({
  website: "pattern",
  study_id: id,
  hospital: {
    name: donationInfo.name,
    city: donationInfo.city,
    state: donationInfo.state
  },
  selectedRaces: donationInfo.selectedRaces,
  birthday: donationInfo.birthday,
  surgeon: donationInfo.surgeon,
  surgeon_phone: donationInfo.surgeon_phone,
  oncologist: donationInfo.oncologist,
  oncologist_phone: donationInfo.oncologist_phone,
  sex: donationInfo.sex,
  surgery_date: donationInfo.surgery_date
});

export const isDonationInfoFormInvalid = (isValid, surgeonPhone, oncologistPhone) => {
  return !isValid ||
      surgeonPhone !== PHONE_PLACEHOLDER &&
      surgeonPhone.includes("_") ||
      oncologistPhone !== PHONE_PLACEHOLDER &&
      oncologistPhone.includes("_");
};

export const isContactInfoFormInvalid = (isValid, phoneNumber) => {
  return !isValid ||
      !phoneNumber ||
      phoneNumber === PHONE_PLACEHOLDER ||
      phoneNumber.includes("_");
};