import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    backgroundColor: "white"
  },
  content: {
    flex: "1 0 auto",
    backgroundColor: "#F9F9F9"
  }
});

export default useStyles;