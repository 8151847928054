//todo fill up the texts
export const i18n = {
  enrollment: {
    screeningForm: {
      tissueExists: "Will you have a biopsy, surgery, or an ascites drain in the contiguous United States or " +
        "Canada in the next 6 months?",
      howDidYouHear: "How did you hear about us?",
      noTissueText1: "Our program currently support fresh tissue samples from a upcoming biopsy/surgery or " +
        "fluid drain.",
      noTissueText2: "However we will be adding new programs in the future to " +
        "support other donations options from either stored tissue or data to research.",
      notifyUs: "Would you like us to notify you when these options become available?"
    },
    eligibilityForm: {
      yourInfoFirstTitle: "This is the first step in the process. Answer these questions to determine your " +
        "eligibility in the program.",
      yourInfoSecondTitle: "Please enter your contact information so we can contact you. We'll use your diagnosis" +
        " to match you with the appropriate research studies.",
      contactInformation: "Please enter your contact information so we can contact you. \n" +
        "We'll use your diagnosis to match you with the appropriate research studies."
    },
  },

};