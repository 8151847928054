export function continueEnrollmentAfterLogin(user)  {

  if (
    !user.profile.hear_about_pattern ||
    (user.profile.notify_of_opportunities === null && user.profile.hear_about_pattern &&
    checkIfArrayContains(user.missing, ["/profile/country"]) &&
    checkIfArrayContains(user.missing, ["/profile/cancer_stage"]))
  ) {
    return 0;
  }

  if (!checkIfArrayContains(user.missing, ["/profile/cancer_diagnosis", "/profile/country"]) &&
    checkIfArrayContains(user.missing, ["/profile/ranked_studies"])) {
    return 1;
  }

  if (checkIfArrayContains(user.missing, ["/profile/ranked_studies"])) {
    return 2;
  }

  if (!checkIfArrayContains(user.missing, ["/profile/ranked_studies"]) &&
    user.profile.timezone === null &&
    checkIfArrayContains(user.missing, ["/profile/hospital_city"])) {
    return 3;
  }

  return -1;
}

export const checkIfArrayContains = (array, values) => {
  return values.every(value => array.includes(value));
};