import { PatternCircularProgress } from "shared/components";
import { StudyInfoView } from "./index";
// import { filterStudies } from "shared/utils/sessionStorageUtils";
import { useConsent, useEnrollment, useUser } from "shared/hooks";

const StudyInfoContainer = () => {

  const { fetchConsent } = useConsent();
  const {
    studyInfoStatus,
    studyInfoByCancer,
    userCancer,
    setSingleStudyProgram,
    setMultipleStudyPrograms,
    selectedStudyPrograms } = useEnrollment();
  const { updateUser } = useUser();

  if (studyInfoStatus !== "succeeded" || !studyInfoByCancer) return <PatternCircularProgress />;

  const handleClick = () => {
    if (selectedStudyPrograms.length === 0) return;
    updateUser({ ranked_studies: selectedStudyPrograms });
    // todo for now we only fetch first
    fetchConsent(selectedStudyPrograms[0]);
  };

  const cancerName = userCancer ? userCancer.display : "";

  return (
    <StudyInfoView
      cancerName={cancerName}
      studyInfoByCancer={studyInfoByCancer}
      selectedStudyPrograms={selectedStudyPrograms}
      setSingleStudyProgram={setSingleStudyProgram}
      setMultipleStudyPrograms={setMultipleStudyPrograms}
      onClick={handleClick}
    />
  );
};

export default StudyInfoContainer;