import { DonationInfoView } from "./index";
import { useAppLocation, useConsent, useUser } from "shared/hooks";

const DonationInfoContainer = () => {

  const { navigateTo } = useAppLocation();
  const { consentStudyName, birthday } = useConsent();
  const { updateUser } = useUser();

  const handleSubmitDonationInfoForm = (donationInfo) => {
    updateUser(donationInfo);
    navigateTo("/success");
  };

  return (
    <DonationInfoView
      birthday={birthday}
      onSubmitDonationInfoForm={handleSubmitDonationInfoForm}
      consentStudyName={consentStudyName}
    />
  );
};

export default DonationInfoContainer;