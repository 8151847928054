import PropTypes from 'prop-types';
import React from 'react';


const ArrowButton = ({ to }) => {
    const scrollTo = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <button
            onClick={() => scrollTo(to)}
            className="absolute bottom-8 left-1/2 transform -translate-x-1/2  border-none shadow-none cursor-pointer focus:outline-none bg-transparent w-full"
            aria-label="Scroll down"
        >
            <svg
                className="w-24 h-24 text-white"
                viewBox="0 0 24 24"
                fill="currentColor"
                stroke="none"
            >
                <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            </svg>
        </button>
    );
};

ArrowButton.propTypes = {
    to: PropTypes.string.isRequired,
    color: PropTypes.string
};

ArrowButton.defaultProps = {
    color: 'white'
};

export default ArrowButton;