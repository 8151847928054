import { createSelector } from "@reduxjs/toolkit";

const enrollmentSelector = state => state.enrollment;

export const selectCurrentEnrollmentStep = createSelector(
  enrollmentSelector,
  enrollment => enrollment.currentEnrollmentStep
);

export const selectMaxEnrollmentStep = createSelector(
  enrollmentSelector,
  enrollment => enrollment.maxEnrollmentStep
);

export const selectNonEligibleDialogState = createSelector(
  enrollmentSelector,
  enrollment => enrollment.isNonEligibleOpen
);

export const selectScreening = createSelector(
  enrollmentSelector,
  enrollment => enrollment.isScreening
);