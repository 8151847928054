import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useStyles } from "../index";
import PropTypes from "prop-types";

const PatternRadioQuestionInfoSource = ({ top, control, title, field, testId }) => {

  const {
    text,
    radioGroup
  } = useStyles();

  const infoSources = ["Foundation", "Google", "Social Media", "Provider", "Recommendation",  "Other"];

  return (
    <Grid item container alignItems="center">
      <Grid item xs={12} md={8}>
        <Box mt={top} mb={1}>
          <Typography className={text} variant="subtitle1" align="flex-start">
            {title}<span title="Required field">&#42;</span>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={12} alignItems="flex-start">
        <Controller
          name={field}
          isClearable
          control={control}
          render={({ field }) =>
            <RadioGroup
              aria-label={field}
              name={field}
              row
              className={radioGroup}
              {...field}
            >
              {infoSources.map((source) => (
                <FormControlLabel
                  key={source}
                  value={source}
                  sx={{ marginRight: "22px" }}
                  control={
                    <Radio
                      sx={{ marginRight: "5px" }}
                      color="primary"
                      inputProps={{
                        "data-testid": `${testId}-{source.toLowerCase()}`,
                      }}
                    />}
                  label={source}
                />
              ))}
            </RadioGroup>
          }
        />
      </Grid>
    </Grid>
  );
};

export default PatternRadioQuestionInfoSource;

PatternRadioQuestionInfoSource.propTypes = {
  top: PropTypes.number,
  control: PropTypes.object,
  title: PropTypes.string,
  field: PropTypes.string,
  testId: PropTypes.string
};