import * as PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { steps } from "../propTypes";
import { useStyles } from "./index";

const ConsentText = ({
  steps,
  currentConsentStep,
}) => {

  const {
    stepHeader,
    consentText,
    textContainer
  } = useStyles();

  return (
    <Grid
      item
      container
      xs={12}
      md={9}
    >
      {steps.map((step, index) => {
        if (index === currentConsentStep) return (
          <Grid item container key={index} className="flex flex-row" >
            <Typography
              className={stepHeader}
              variant="h2"

            >
              {step.sequence}. {step.section_title}
            </Typography>

            <Grid item xs={12} className={textContainer}>
              {step.text.map((textLine, index) => (
                <Box
                  key={index}
                  mt={0}
                  mb={2}
                  className={consentText}
                  dangerouslySetInnerHTML={{ __html: textLine }}
                >
                </Box>
              ))}
            </Grid>

          </Grid>
        );
      })}
    </Grid>
  );
};

ConsentText.propTypes = {
  steps,
  currentConsentStep: PropTypes.number,
};

export default ConsentText;