import * as PropTypes from "prop-types";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import { ProgramsItem } from "shared/components";
import { useStyles } from "./index";

const MatchedPrograms = ({ matchedPrograms, onViewDetails, selectedProgramIds, onSelectProgram }) => {
  const { matchedProgramsContainer, selectedProgramStyle } = useStyles();

  const handleSelectProgram = (programId) => {
    onSelectProgram(programId);
  };

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ height: "100vh" }}
    >
      <Grid item container mb={3}>
        <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "3rem", color: "#46444D" }}>
          Matched Programs
        </Typography>
        <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "1.8rem", color: "#7D7987" }}>
          If you have a preference, select the program you’d like to be matched with or continue with the our
          recommendation. We can’t promise the selected study is where the sample will go but whenever  possible,
          we’ll prioritize your rankings/preferences
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={3}
        justifyContent="left"
        className="h-[700px]"
        sx={{ overflowY: "auto", height: "50vh", padding: "20px 20px" }}
      >
        {matchedPrograms.map((program) => (
          <Grid
            key={program.id}
            item
            container
            xs={12}
            lg={6}
            sx={{ height: "40px" }}
          >
            <Box
              onClick={() => handleSelectProgram(program.id)}
              className={
                matchedProgramsContainer + " " +
                (selectedProgramIds?.includes(program.id) ?
                  selectedProgramStyle : "")}
            >
              <ProgramsItem
                study={program.study}
                onClick={() => onViewDetails(program)}
                isSelectable={true}
              />
            </Box>

          </Grid>
        ))}
      </Grid>

    </Grid>
  );
};

MatchedPrograms.propTypes = {
  matchedPrograms: PropTypes.any,
  onViewDetails: PropTypes.func,
  selectedProgramIds: PropTypes.array,
  onSelectProgram: PropTypes.func
};

export default MatchedPrograms;