import { Grid } from "@mui/material";
import { ProgramsItem } from "shared/components";
import { useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import PropTypes from "prop-types";


export const ProgramsCarousel = ({ studiesInChunks, onViewDetails }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <Carousel
      fullHeightHover={true}
      animation="slide"
      autoPlay={false}
      navButtonsAlwaysVisible={!isMobile}
      sx={{ paddingTop: "20px" }}
    >
      {studiesInChunks.map((studyChunk, index) => {
        return (
          <CarouselGroup key={index} studyChunk={studyChunk} onViewDetails={(studyItem) => onViewDetails(studyItem)} />
        );
      })}
    </Carousel>
  );
};

ProgramsCarousel.propTypes = {
  studiesInChunks: PropTypes.any,
  onViewDetails: PropTypes.func
};

const CarouselGroup = ({ studyChunk, onViewDetails }) => {
  return (
    <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 pl-24 pr-24">
      {studyChunk.map((studyItem, index) => {
        return (
          <Grid item key={index} >
            <ProgramsItem
              study={studyItem.study}
              onClick={() => onViewDetails(studyItem)}
              width="412px"
            />
          </Grid>
        );
      })}
    </div>
  );
};

CarouselGroup.propTypes = {
  studyChunk: PropTypes.any,
  onViewDetails: PropTypes.func
};