import { StudyInfoService } from "services/studyInfoService";
import { call, put } from "redux-saga-test-plan/matchers";
import {
  failure,
  getAllStudies,
  getStudyInfoByCancer,
  pending,
  successGetAllStudies,
  successGetStudyInfoByCancer
} from "../../redux/slices/studyInfoSlice";
import { takeLatest } from "redux-saga/effects";

export const studyInfoSaga = [
  takeLatest(getStudyInfoByCancer, handleFetchStudyInfoByCancer),
  takeLatest(getAllStudies, handleFetchAllStudies)
];

function* handleFetchStudyInfoByCancer({ payload }) {

  const { cancer_id } = payload;

  try {
    yield put(pending());
    const response = yield call(StudyInfoService.getStudyList, cancer_id);
    yield put(successGetStudyInfoByCancer(response));
  } catch (error) {
    yield put(failure(error));
  }
}

function *handleFetchAllStudies() {
  try {
    yield put(pending());
    const response = yield call(StudyInfoService.getAllStudies);
    yield put(successGetAllStudies(response));
  } catch (error) {
    yield put(failure(error));
  }
}