import { UserService } from "services/userService";
import { call, put } from "redux-saga-test-plan/matchers";
import {
  failure,
  getUser,
  patchUser,
  pending,
  saveAndPatchUser,
  saveUser,
  success
} from "../../redux/slices/userSlice";
import { getStudyInfoByCancer } from "../../redux/slices/studyInfoSlice";
import { saveDonationInfo } from "../../redux/slices/donationInfoSlice";
import { serialize } from "shared/utils/sessionStorageUtils";
import { serializePatchUserData } from "shared/utils/formUtils";
import { setCancer } from "../../redux/slices/cancersSlice";
import { setEnrollmentStep } from "../../redux/slices/enrollmentSlice";
import { takeLatest } from "redux-saga/effects";

export const userSaga = [
  takeLatest(getUser, handleFetchUser),
  takeLatest(saveUser, handleSaveUser),
  takeLatest(patchUser, handlePatchUser),
  takeLatest(saveAndPatchUser, handleCreateAndPatchUser)
];

function* handleFetchUser() {
  try {
    yield put(pending());
    const response = yield call(UserService.getUser);
    serialize("personalData", response.data);
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}

function* handleSaveUser({ payload }) {
  try {
    yield put(pending());

    const response = yield call(UserService.saveUser, payload);
    yield put(success(response));
    serialize("personalData", response.data);
  } catch (error) {
    yield put(failure(error));
  }
}

function* handlePatchUser({ payload }) {
  try {
    yield put(pending());
    const patchUserData = serializePatchUserData(payload);
    const response = yield call (UserService.patchUser, patchUserData);
    serialize("personalData", response.data);
    if (response.data.profile.cancer_diagnosis) {
      yield put(getStudyInfoByCancer({ cancer_id: response.data.profile.cancer_diagnosis }));
      yield put(setEnrollmentStep({ currentEnrollmentStep: 1, maxEnrollmentStep: 1 }));
      yield put(setCancer({id: response.data.profile.cancer_diagnosis}));
    }

    if (response.data.profile.hospital_name) {
      yield put(saveDonationInfo());
    }
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}

function* handleCreateAndPatchUser({ payload }) {
  try {
    yield put(pending());
    const response = yield call(UserService.saveUser, payload.forCreate);
    yield put(success(response));
    const patchUserData = serializePatchUserData(payload.forPatch);
    const patchResponse = yield call(UserService.patchUser, patchUserData);
    serialize("personalData", patchResponse.data);
    yield put(success(patchResponse));
  } catch (error) {
    yield put(failure(error));
  }
}