import { CurrentPrograms, WhitePanel } from "./WhitePanel";
import { GreenPanel } from "./GreenPanel";
import { PartnersSection } from "./PartnersPanel";
import { WelcomingPanel } from "./WelcomingPanel";

import WhitePanelStudies from "./WhitePanel/WhitePanelStudies";

export const LandingHome = () => {

  return (
    <>
      <WelcomingPanel />
      <WhitePanel />
      <WhitePanelStudies />
      <GreenPanel />
      <PartnersSection />
      {/*TODO to be removed*/}
      {/*<CtaFooter/>*/}
    </>
  );

};