import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "5.5rem",
    fontWeight: 500,
    color: theme.palette.pattern.blue,
  },
  successCard: {
    padding: theme.spacing(3)
  },
  blue: theme.palette.pattern.blue,
  green: theme.palette.pattern.green,
  yellow: theme.palette.pattern.yellow,
  link: {
    color: "#0090D0",
    fontSize: "1.2rem",
    fontWeight: 500,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  linkHover: {
    cursor: "pointer"
  }
}));

export default useStyles;