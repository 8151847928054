import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  consentHelpBtn: {
    textTransform: "none",
    backgroundColor: theme.palette.pattern.consentHelp,
    color: theme.palette.pattern.blackDialog,
    "&:hover": {
      backgroundColor: theme.palette.pattern.disabledStep
    },
    "& svg": {
      fontSize: "2rem"
    }
  },
  btnWrapper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  // fullName: {
  //   position: "relative"
  // },
  label: {
    fontSize: "2.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
}));

export default useStyles;