import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Grow,
  Typography
} from "@mui/material";
import {
  removeHtmlTagsAndTruncate,
} from "shared/utils/strings";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const ProgramsItem = ({
  study,
  onClick,
  width = "auto",
  height = "auto",
}) => {
  const {
    logoContainer,
    titleStyle,
    textContainer,
    descriptionStyles,
    button,
  } = useStyles();

  const { logoUrl, name, description } = study;
  const descriptionText = removeHtmlTagsAndTruncate(description.toString());

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="left"
      className=""
    >
      <Card
        elevation={4}
        sx={{
          bgcolor: "white",
          height: `${height}`,
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <Grid item container xs={12} gap={2} height={height}>
          <Grid item>
            <Grow in={logoUrl}>
              <CardMedia
                component="img"
                className={logoContainer}
                image={logoUrl}
                alt="title"
              />
            </Grow>
          </Grid>

          <Grid item>
            <CardContent className={textContainer} sx={{ paddingBottom: 0 }}>
              <Typography className={titleStyle}>{name}</Typography>
              <Typography
                className={descriptionStyles}
              >
                {descriptionText}
              </Typography>
            </CardContent>
          </Grid>

          <Grid item justifyContent="flex-start">
            <CardActions sx={{ paddingLeft: 0, paddingBottom: 0 }}>
              <Button className={button} variant="contained" onClick={onClick}>Details</Button>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </Grid>

  );
};

export default ProgramsItem;

ProgramsItem.propTypes = {
  study: PropTypes.object,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string
};