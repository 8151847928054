import { getCancers } from "../../redux/slices/cancersSlice";
import { selectCancers } from "../../redux/selectors/cancersSelector";
import { useDispatch, useSelector } from "react-redux";

export const useCancers = () => {
  const dispatch = useDispatch();

  const fetchCancers = () => {
    dispatch(getCancers());
  };

  const cancers = useSelector(selectCancers);

  return {
    fetchCancers,
    cancers
  };
};