import { YourInfoView } from "./index";
import { filteredObject } from "shared/utils/objects";
import {
  useAppLocation,
  useEnrollment,
  useEnrollmentDialog,
  useEnrollmentStepper,
  useUser
} from "shared/hooks";
import { useState } from "react";

const YourInfoContainer = () => {

  const {
    isNonEligibleDialogOpen,
    setNonEligibleDialog,
    setVisibilityScreening,
  } = useEnrollmentDialog();

  const { updateEnrollmentStep } = useEnrollmentStepper();

  const {
    isScreening,
    cancers,
    resetEnrollment,
    fetchStudyInfoByCancer,
    studyInfoByCancer
  } = useEnrollment();

  const {
    updateUser,
    createAndPatchUser,
    sessionPersonalInfo,
    userStatus,
    userError,
    logoutUser,
    clearUserErrors
  } = useUser();

  const { navigateTo } = useAppLocation();

  const [ email ] = useState("");

  const handleSubmitScreeningForm = (formData) => {
    //todo maybe move to saga
    const userDataForCreate = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email
    };

    if (formData.tissueExists === "Yes") {
      const userDataForPatch = {
        hear_about_pattern: formData.hear_about_pattern,
      };
      createAndPatchUser({ forCreate: userDataForCreate, forPatch: userDataForPatch });
      setVisibilityScreening(false);
    }

    if (formData.tissueExists === "No" && formData.notify_of_opportunities === "No") {
      navigateTo("/");
      resetEnrollment();
    }

    if (formData.tissueExists === "No" && formData.notify_of_opportunities === "Yes") {
      const userDataForPatch = {
        cancer_diagnosis: formData.cancer_diagnosis,
        cancer_stage: formData.cancer_stage
      };

      createAndPatchUser({ forCreate: userDataForCreate, forPatch: userDataForPatch });
      navigateTo("/");
      resetEnrollment();
    }
  };

  const handleCancerSelect = (cancerId) => {
    fetchStudyInfoByCancer(cancerId);
  };

  const handleSubmitPersonalInfoForm = formData => {
    const objFromFormData = {
      cancer_diagnosis: formData.cancer_diagnosis,
      cancer_stage: formData.cancer_stage,
      occurrence_type: formData.occurrence_type,
      site: formData.site,
      tumor_size: formData.tumor_size,
      donate_to_multiple: formData.donate_to_multiple === "Yes",
      previous_treatments: formData.previous_treatments,
      mailing_address_1: formData.mailing_address_1,
      mailing_address_2: formData.mailing_address_2,
      city: formData.city,
      postcode: formData.postcode,
      state: formData.state,
      country: formData.country,
      phone_number: formData.phone_number
    };
    const preparedDataForPatch = filteredObject(objFromFormData);
    updateUser(preparedDataForPatch);
  };

  const handleCloseNonEligibilityDialog = () => setNonEligibleDialog(false);

  const handleGoToNextStep = () => updateEnrollmentStep({ currentEnrollmentStep: 1 });

  const handleNavigateToAccount = () => navigateTo("/dashboard");

  const handleCloseErrorDialog = () => logoutUser();

  const handleClearErrors = () => clearUserErrors();

  return (
    <YourInfoView
      userStatus={userStatus}
      email={email}
      userError={userError}
      cancers={cancers}
      isLogged={!!sessionPersonalInfo}
      isNotEligibleDialogOpen={isNonEligibleDialogOpen}
      isScreeningForm={isScreening}
      onSubmitScreeningForm={handleSubmitScreeningForm}
      onSubmitPersonalInfoForm={handleSubmitPersonalInfoForm}
      onCloseNonEligibleDialog={handleCloseNonEligibilityDialog}
      onGoToNextStep={handleGoToNextStep}
      onNavigateToAccount={handleNavigateToAccount}
      onCloseErrorDialog={handleCloseErrorDialog}
      clearErrors={handleClearErrors}
      onCancerSelect={handleCancerSelect}
      numberOfStudiesBasedOnCancer={studyInfoByCancer.length}
    />
  );
};

export default YourInfoContainer;