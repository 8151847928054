import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    fontWeight: 500,
    fontSize: "2rem",
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  link: {
    color: theme.palette.pattern.white,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
}));

export default useStyles;