import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "15rem",
    paddingRight: "15rem",
    [theme.breakpoints.up('md')]: {
      fontSize: "4rem",
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    maxHeight: "100vh",
  },
  title: {
    color: theme.palette.pattern.grayNew,
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      fontSize: "4rem",
    },
  },
  paragraph: {
    color: theme.palette.pattern.grayNew,
    fontSize: "2rem",
    fontWeight: 400
  }
}));

export default useStyles;