import { Grid, Typography } from "@mui/material";
import Ellipse from "assets/images/elements/Ellipse.svg";
import PropTypes from "prop-types";

const GreenPanelStepsMobile = ({ classes, stepBubbles }) => {

  const { stepImages, avatar, avatarSubtitle } = classes;

  return (
    <>


      {/* <div className="flex justify-end col-span-1">
              <Grid item container xs={4} justifyContent="center" >
                <div className="relative w-24 h-24 bg-white rounded-full flex items-center justify-center">
                  <img
                    src={src}
                    alt={alt}
                    className="w-4/5 h-4/5 object-contain"
                  />
                </div>
              </Grid>
            </div> */}


      {stepBubbles.map(({ src, alt, text }, index) => {
        return (
          <div
            key={index}
            className="flex flex-col items-center justify-center w-3/4 mx-auto pt-10"
          >
            <Grid item container xs justifyContent="center" >
              <div className="relative w-28 h-28 bg-white rounded-full flex items-center justify-center mb-5">
                <img
                  src={src}
                  alt={alt}
                  className="w-20 h-20 object-contain"
                />
              </div>
            </Grid>
            <Grid item container xs justifyContent="center" >
              <Typography
                className={avatarSubtitle}
                variant="h3"
                align="center"
              >
                {text}
              </Typography>
            </Grid>
          </div>
        );
      })}
    </>
  );
};

GreenPanelStepsMobile.propTypes = {
  classes: PropTypes.object,
  stepBubbles: PropTypes.array
};

GreenPanelStepsMobile.defaultProps = {
  classes: null,
  stepBubbles: null
};

export default GreenPanelStepsMobile;