import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      height: "800px",
    },
    [theme.breakpoints.down("md")]: {
      height: "1600px",
    },

    backgroundColor: theme.palette.pattern.green
  },
  title: {
    fontSize: "3.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.2rem",
      marginBottom: theme.spacing(1),
    }
  },
  subtitle: {
    color: theme.palette.pattern.white,
    fontSize: "5rem",
    fontWeight: 600,
    marginTop: "63px",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.8rem",
      marginBottom: theme.spacing(1),
      marginTop: "20px",
    }
  },
  button: {
    marginTop: "62px",
    marginBottom: "62px",
    width: "300px",
    height: "65px",
    fontWeight: 500,
    fontSize: "2.8rem",
    backgroundColor: theme.palette.pattern.white,
    color: theme.palette.primary.main,
    shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.8rem",
    },
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.pattern.white,
      color: theme.palette.pattern.consentBorder,
      borderColor: theme.palette.pattern.consentBorder
    },
  }
}));

export default useStyles;