import { Box, Grid, Typography } from "@mui/material";
import { Partners, useStyles } from "./index";
import { useStaticStuff } from "../../../shared/hooks";

const PartnersSection = () => {

  const { root, paragraph, title } = useStyles();

  const { foundationPartners } = useStaticStuff();

  return (
    <Grid
      item
      container
      xs={12}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={root}
    >
      {/*todo to be removed*/}
      {/*<Box mt={5}>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <img src={Logo} alt="logo" className={logo}/>*/}
      {/*  </Grid>*/}
      {/*</Box>*/}

      <Box mt={5}>
        <Grid item container xs={12} justifyContent="center">
          <Grid item xs={11} md={8}>
            <Typography variant={"h3"} className={paragraph} align="center">
              Pattern.org is part of the Rare Cancer Research Foundation,
              a nonprofit dedicated to curing rare cancers through collaborations that catalyze effective research
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5} mb={5}>
        <Grid item container xs={12} justifyContent="center">
          <Typography variant={"h3"} className={title} align="center">
            Some of RCRF&lsquo;s Current Partners
          </Typography>
        </Grid>
      </Box>

      <Partners foundationPartners={foundationPartners}/>
    </Grid>
  );
};

export default PartnersSection;