import { UserService } from "services/userService";
import { call, put } from "redux-saga-test-plan/matchers";
import { checkIfArrayContains, continueEnrollmentAfterLogin } from "shared/utils/authEnrollmentUtil";
import { clearSession, serialize } from "shared/utils/sessionStorageUtils";
import {
  failure,
  getPasscode,
  leave,
  login,
  logout,
  logoutSuccess,
  pending,
  success
} from "../../redux/slices/authSlice";
import { fetchStaticStuff } from "../../redux/slices/staticStuffSlice";
import { getConsent } from "../../redux/slices/consentSlice";
import { getStudyInfoByCancer } from "../../redux/slices/studyInfoSlice";
import { setCancer } from "../../redux/slices/cancersSlice";
import { setEnrollmentStep, toggleVisibilityScreening } from "../../redux/slices/enrollmentSlice";
import { setUser } from "../slices/userSlice";
import { takeLatest } from "redux-saga/effects";

export const authSaga = [
  takeLatest(getPasscode, handleGetPasscode),
  takeLatest(login, handleLogin),
  takeLatest(logout, handleLogout),
  takeLatest(leave, handleLeave)
];

function* handleGetPasscode({ payload }) {
  try {
    yield put(pending());
    yield call (UserService.getPasscode, payload);
    yield put(success(payload));
  } catch (error) {
    yield put(failure(error));
  }
}

function* handleLogin({ payload }) {
  const { email, passcode, navigateTo } = payload;
  try {
    yield put(pending());
    const response = yield call (UserService.loginUser, email, passcode);
    yield put(success(response));
    yield put(setUser(response));
    serialize("personalData", response.data);
    const userData = response.data;
    const enrollmentStepAfterLogin = continueEnrollmentAfterLogin(userData);
    if (enrollmentStepAfterLogin !== -1) {
      if (enrollmentStepAfterLogin === 0 &&
        userData.profile.hear_about_pattern &&
        checkIfArrayContains(userData.missing, ["/profile/cancer_stage"])) {
        yield put(toggleVisibilityScreening(false));
      }

      if (enrollmentStepAfterLogin === 1 || enrollmentStepAfterLogin === 2 || enrollmentStepAfterLogin === 3) {
        yield put(setCancer({id: userData.profile.cancer_diagnosis}));
        yield put(getStudyInfoByCancer({cancer_id: userData.profile.cancer_diagnosis }));
      }

      if (enrollmentStepAfterLogin === 2 || enrollmentStepAfterLogin === 3) {
        yield put(getConsent({ study_id: userData.profile.ranked_studies[0] }));
      }

      yield put(setEnrollmentStep({
        currentEnrollmentStep: enrollmentStepAfterLogin ,
        maxEnrollmentStep: enrollmentStepAfterLogin
      }));

      navigateTo("/enrollment");
    } else {
      navigateTo("/");
    }
  } catch (error) {
    yield put(failure(error));
  }
}

function* handleLogout({ payload }) {
  const navigateTo = payload.navigateTo;
  try {
    yield put(pending());
    yield call (UserService.logoutUser);
    clearSession();
    yield put(logoutSuccess());
    yield put(fetchStaticStuff());
    navigateTo("/");
  } catch (error) {
    yield put(failure(error));
  }
}

function handleLeave() {
  clearSession();
}


