import { Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

const FooterLinksMobile = ({ classes }) => {

  const { linkWrapper, link } = classes;

  return (
    <Typography className={linkWrapper} align="center">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={1}
      >
        <Grid item xs={2}>
          <Link className={link} component={RouterLink} to="/">Home</Link>
        </Grid>
        <Grid item xs={2}>
          <Link className={link} href="https://rarecancer.org/news-updates" target="_blank" rel="noreferrer">Blog</Link>
        </Grid>
        <Grid item xs={1}>
          <Link className={link} component={RouterLink} to="/about">About</Link>
        </Grid>
        <Grid item xs={1}>
          <Link className={link} component={RouterLink} to="/faq">FAQ</Link>
        </Grid>
        <Grid item xs={1}>
          <Link className={link} href="https://rarecancer.org/" target="_blank" rel="noreferrer">RCRF</Link>
        </Grid>
        <Grid item xs={3}>
          <Link className={link} href="mailto:info@pattern.org" target="_blank">Contact</Link>
        </Grid>
        <Grid item xs={2}>
          <Link className={link} component={RouterLink} to="/Terms">Terms</Link>
        </Grid>
        <Grid item xs={2}>
          <Link className={link} component={RouterLink} to="/Privacy">Privacy</Link>
        </Grid>
        <Grid item xs={1}>
          <Link className={link} href="https://rarecancer.org/newsletter" target="_blank" rel="noreferrer">
            Mailing List
          </Link>
        </Grid>
      </Grid>
    </Typography>
  );
};

FooterLinksMobile.propTypes = {
  classes: PropTypes.object
};

FooterLinksMobile.defaultTypes = {
  classes: null
};

export default FooterLinksMobile;