import 'tailwindcss/tailwind.css';
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: "#0090D0"
    },
    secondary: {
      main: "#61CAA1",
      // mainOld: "#1f9b6a"
    },
    error: {
      main: "#d08405"
    },
    pattern: {
      body: "#212121",
      greenOld: "#24b57c",
      green: "#61CAA1",
      blue: "#0090d0",
      yellow: "#f9a51a",
      black: "#2b2b2b",
      blackDisabled: "rgba(0, 0, 0, 0.28)",
      blackDialog: "rgba(0, 0, 0, 0.87)",
      disabledStep: "#d5d5d5",
      consentHelp: "#fafafa",
      gray: "#7e7e7e",
      grayNew: "#7D7987",
      grayTitle: "#46444D",
      success: "#8BC34A",
      error: "#F44336",
      warning: "#FF9800",
      mainBg: "#00aef0",
      detailsBtn: "#0085CA",
      consentBorder: "#00609f",
      newBlue: "#E8EFFB",
      newTextBlue: "#1F6EA3",
      secondaryBg: "#0ea886",
      white: "#FFFFFF",
      customBtn: "#0ea886",
      twitter: "#55acee",
      facebook: "#3b5998",
      link: "#00BCD4",
      tableBorder: "#f2f2f2",
      header: "rgba(255,255,255,0.87)",
      grayChip: "#e0e0e0",
      dividerGray: "#E5E5E5",
      stepTextGray: "#C4C4C4",
      enrollmentDividerGray: "#D9D9D9",
      enrollmentBackground: "#F9F9F9",
      grayChipText: "#424242",
      transparent: "transparent",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      // "Raleway",
      // "Helvetica Neue",
      // "sans-serif"
    ].join(","),
    // tell material what the font-size on the html element is.
    htmlFontSize: 10
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: "62.5%"
        },
        "*": {
          "scrollbarWidth": "thin",
        },
        // a nice thin scrollbar on all popovers (put * for all containers instead)
        ".MuiPaper-root.MuiMenu-paper.MuiPopover-paper": {
          minWidth: "136px",
          minHeight: "48px",
          maxHeight: "256px",
          overflowY: "auto"
        },
        ".MuiPaper-root.MuiMenu-paper.MuiPopover-paper::-webkit-scrollbar": {
          width: "8px",
          background: "rgba(0,0,0,0.1)",
          transition: "all .2s"
        },
        ".MuiPaper-root.MuiMenu-paper.MuiPopover-paper::-webkit-scrollbar-thumb": {
          background: "rgba(0,0,0,0.15)",
          borderRadius: "4px",
          transition: "all .2s"
        },
        ".MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary": {
          backgroundColor: "white"
        }
        // ".MuiChip-icon": {
        //   color: "white !important"
        // }
        // body: {
        //   fontSize: "0.875rem",
        //   lineHeight: 1.43,
        //   letterSpacing: "0.01071em",
        // }
        // todo styles setup from the intercom website
        // ".intercom-lightweight-app-launcher": {
        //   backgroundColor: "#24b57c !important"
        // }
      }
    }
  }
});

export default theme;