import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginTop: "",
    width: "80%",
    paddingBottom: theme.spacing(4),
  },
  imgWrapper: {
    justifyContent: "center"
  },
  img: {
    margin: theme.spacing(2),
    width: "50%"
  },
  consentForm: {
    // border: `2px solid ${theme.palette.pattern.consentBorder}`
  },
  amendmentInfo: {
    margin: theme.spacing(1),
    fontSize: "1.4rem",
    fontWeight: 700,
    color: theme.palette.pattern.disabledStep
  },
  stepHeader: {
    fontSize: "2.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.blue,
    margin: "1rem auto"
  },
  consentText: {
    fontSize: "1.8rem",
    fontWeight: 400,
    "& li": {
      listStyleType: "none",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.pattern.secondaryBg,
      "&:hover": {
        textDecoration: "underline"
      }
    }
  },
  button: {
    width: "auto",
    height: "61px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.8rem",
    textTransform: "none"
  },
}));

export default useStyles;