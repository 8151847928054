import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const initialState = {
  consent: null,
  consentList: [],
  currentConsentStep: 0,
  maxConsentStep: 0,
  consentFormBirthday: null,
  status: "idle",
  error: null
};

const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    getConsent(action) {},
    // eslint-disable-next-line no-unused-vars
    setConsentStep(state, action) {
      state.currentConsentStep = action.payload.currentConsentStep;
      if (action.payload.maxConsentStep || action.payload.maxConsentStep === 0) {
        state.maxConsentStep = action.payload.maxConsentStep;
      }
    },
    addToConsentList(state, action) {
      state.consentList = _.unionBy(state.consentList, [action.payload], "consent_form_id");
    },
    resetConsentStep(state) {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    },
    setConsentFormBirthday(state, action) {
      state.consentFormBirthday = action.payload;
    },
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.consent = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  getConsent,
  postConsent,
  setConsentStep,
  setConsentFormBirthday,
  pending,
  success,
  failure,
  resetConsentStep
} = consentSlice.actions;
export default consentSlice.reducer;