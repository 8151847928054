import { Box, Typography } from "@mui/material";
import Parse from "html-react-parser";
import PropTypes from "prop-types";

const StudyDescription = ({ description }) => {
  return (
    <Box>
      {description.map((descriptionItem, index) => (
        <Typography key={index} >
          {Parse(descriptionItem)}
        </Typography>
      ))}
    </Box>
  );
};

export default StudyDescription;

StudyDescription.propTypes = {
  description: PropTypes.object
};