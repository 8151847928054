import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: "flex-end"
  },
  label: {
    fontSize: "2.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
}));

export default useStyles;