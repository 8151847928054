import { Box, Chip, Typography } from "@mui/material";
import { useStyles } from "../index";
import CellLines from "assets/new-icons/cell-lines.svg";
import DrugDiscovery from "assets/new-icons/drug-discovery.svg";
import PropTypes from "prop-types";
import Sequencing from "assets/new-icons/sequencing.svg";


const ExampleUses = ({ researchTypeTitles, onClick }) => {

  const {
    chip,
  } = useStyles();

  const renderIcon = (title) => {
    switch (title) {
      case "Cell line":
        return <img src={CellLines} alt={title} width={45} height={45} />;
      case "Drug Discovery":
        return <img src={DrugDiscovery} alt={title} width={45} height={45} />;
      case "Sequencing":
        return <img src={Sequencing} alt={title} width={45} height={45} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box mb={3}>
        <Typography className="text-gray-600 font-semibold text-5xl pl-1 sm:pl-2 ">Example Uses</Typography>
      </Box>
      <div className="h-full overflow-scroll flex gap-4 flex-col pl-1 sm:pl-4">

        {researchTypeTitles.map((title) => (
          <div key={`chip-${title}`} className="" >
            <Chip
              label={title}
              className={chip}
              onClick={() => onClick(title)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ExampleUses;

ExampleUses.propTypes = {
  researchTypeTitles: PropTypes.array,
  onClick: PropTypes.func
};