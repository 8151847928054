import { DonationInfoService } from "services/donationInfoService";
import { call, put, select } from "redux-saga-test-plan/matchers";
import {
  failure,
  pending,
  success
} from "../../redux/slices/userSlice";
import { saveDonationInfo } from "../slices/donationInfoSlice";
import { selectConsentFormId } from "../selectors/consentSelector";
import { takeLatest } from "redux-saga/effects";

export const donationInfoSaga = [
  takeLatest(saveDonationInfo, handleSaveDonationInfo),
];

function* handleSaveDonationInfo() {
  try {
    yield put(pending());
    const consentFormId = yield select(selectConsentFormId);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(DonationInfoService.saveDonationInfo, { timezone, consent_form_ids: [consentFormId] });
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}