import { Box, Divider, Grid, Grow, Typography } from "@mui/material";
import { ConsentStepsMobile, useStyles } from "./index";
import { steps } from "../propTypes";
import PropTypes from "prop-types";

const ConsentHeaderMobile = ({
  logoUrl,
  name,
  steps,
  maxConsentStep,
  currentConsentStep,
  onStepMobileClick
}) => {

  const {
    title,
    imgContainer,
    imgMobile,
    divider
  } = useStyles();

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
      >
        <Box mt={3} mb={3}>
          <Typography variant="h1" align="center" className={title}>
            Review Consent Form
          </Typography>
        </Box>
      </Grid>
      <ConsentStepsMobile
        steps={steps}
        maxConsentStep={maxConsentStep}
        currentConsentStep={currentConsentStep}
        onStepChange={onStepMobileClick}
      />
    </>
  );
};

ConsentHeaderMobile.propTypes = {
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  steps,
  maxConsentStep: PropTypes.number,
  currentConsentStep: PropTypes.number,
  onStepMobileClick: PropTypes.func
};

export default ConsentHeaderMobile;