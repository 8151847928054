import { AboveTwentyOne } from "../../../AboveTwentyOne";
import { Controller } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { PatternDatePicker, PatternTextField } from "shared/components";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const ConsentFinalStepForm = ({ control, errors, consentStudyName }) => {

  const { label } = useStyles();

  return (
    <Grid
      item
      container
      xs={6}
      justifyContent="flex-end"
      alignItems="center"
      direction="column"
      sx={{ margin: "0 10rem" }}
    >
      {consentStudyName === "Cancer Cell Line Project" && (
        <Grid item>
          <AboveTwentyOne />
        </Grid>
      )}

      <Grid item container className="flex flex-col w-full">
        <Grid item className="w-full">
          <Typography className={label} mb={1} mt={1}>
            Full Name<span title="Required field">&#42;</span>
          </Typography>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) =>
              <PatternTextField
                variant="outlined"
                color="primary"
                fullWidth
                error={!!errors.fullName}
                helperText={errors.fullName ? "This is required" : ""}
                required
                data-testid="consent-full-name"
                {...field}
              />
            }
          />
        </Grid>
        <Grid item className="w-full">
          <Typography className={label} mb={1} mt={1}>
            Date of Birth<span title="Required field">&#42;</span>
          </Typography>
          <PatternDatePicker
            name="birthday"
            control={control}
            testId={"consent-birthday"}
          />
        </Grid>
      </Grid>

    </Grid>
  );
};

ConsentFinalStepForm.propTypes = {
  consentStudyName: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object
};

export default ConsentFinalStepForm;