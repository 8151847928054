import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "63px",
    [theme.breakpoints.down("md")]: {
      marginTop: "4px",
    }

  },
  stepImages: {
    marginBottom: "45px"
  },
  avatarContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
  },
  avatar: {
    position: "relative",
    top: "-115px",
    height: "60px",
    width: '60%',
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(3)
    }
  },
  avatarSubtitle: {
    color: theme.palette.pattern.white,
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: "3rem",
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
    },
  }
}));

export default useStyles;