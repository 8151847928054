import { AboveTwentyOne } from "../../index";
import { Button, Divider, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PatternDatePicker, PatternMultiSelect, PatternPhoneField, PatternTextField } from "shared/components";
import { PatternSelect } from "shared/components/PatternSelect";
import { defaultDonationInfoValues, donationInfoSchema, isDonationInfoFormInvalid } from "shared/utils/formUtils";
import { getAllStatesAndProvinces, races } from "shared/constants/arrays";
import { useEffect } from "react";
import { useStyles } from "./index";
import { yupResolver } from "@hookform/resolvers/yup";
import PatternMultilineTextField from "shared/components/PatternMultilineTextField/PatternMultilineTextField";
import PropTypes from "prop-types";

const DonationInfoForm = ({ onSubmit, birthday, consentStudyName }) => {

  const {
    formWidth,
    formField,
    subtitle,
    button,
    title,
    label,
    paragraph,
    dividerStyle
  } = useStyles();

  const methods = useForm({
    defaultValues: defaultDonationInfoValues,
    mode: "all",
    resolver: yupResolver(donationInfoSchema)
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { isValid, errors }
  } = methods;

  useEffect(() => {
    if (birthday) {
      setValue("birthday", birthday);
    }
  }, [birthday]);

  const surgeonPhone = watch("surgeon_phone");
  const oncologistPhone = watch("oncologist_phone");
  const selectedRaces = watch("selected_races");

  const getRaces = () => {
    if (selectedRaces.length && selectedRaces.includes("Prefer not to answer")) {
      return races.map(race => {
        return race.text !== "Prefer not to answer" ?
          { text: race.text, disabled: true } : { text: race.text, disabled: false };
      });
    } else {
      return races;
    }
  };

  const handleSelect = (value, name) => {
    if (value.length && value.includes("Prefer not to answer")) {
      setValue(name, ["Prefer not to answer"]);
    } else {
      return setValue(name, value.filter(val => val !== "Prefer not to answer"));
    }
  };

  return (
    <>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        xs={12}
      >
        <form
          className={formWidth}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
        >
          <Paper
            elevation={3}
            sx={{
              padding: "80px",
              marginTop: "50px",
              marginBottom: "40px"
            }}>

            <Grid item>
              <Typography variant="h1" className={title} align="center" mb={3}>
                Donation Information
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h3" align="center" className={paragraph} mb={6}>
                This information will help us arrange your donation.
                Please include as much information as you are able.
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item container xs>
                <Typography
                  variant="h2"
                  className={subtitle}
                  align="left"
                >
                  Hospital and Procedure Information
                </Typography>
              </Grid>

              <Divider component="div" className={dividerStyle} />

              {/* Surgery Date */}
              <Grid item container className={formField}>
                <Typography className={label} mb={1} mt={1}>Surgery/Ascites/Biopsy Date</Typography>
                <Controller
                  name="surgery_date"
                  control={control}
                  render={({ field }) =>
                    <PatternTextField
                      variant="outlined"
                      color="primary"
                      fullWidth
                      inputProps={{
                        "data-testid": "surgery_date"
                      }}
                      {...field}
                    />
                  }
                />
              </Grid>

              {/* Hospital Name */}
              <Grid item container className={formField}>
                <Typography className={label} mb={1} mt={1}>Hospital Name<span
                  title="Required field">&#42;</span></Typography>
                <Controller
                  name="hospital_name"
                  control={control}
                  render={({ field }) =>
                    <PatternTextField
                      variant="outlined"
                      color="primary"
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name ? "Please enter your hospital name" : ""}
                      required
                      inputProps={{
                        "data-testid": "name"
                      }}
                      {...field}
                    />
                  }
                />
              </Grid>

              <Grid item container spacing={3}>
                {/* Hospital City */}
                <Grid item container className={formField} xs={12} lg={6}>
                  <Typography className={label} mb={1} mt={1}>Hospital City<span
                    title="Required field">&#42;</span></Typography>
                  <Controller
                    name="hospital_city"
                    control={control}
                    render={({ field }) =>
                      <PatternTextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        error={!!errors.city}
                        helperText={errors.city ? "Please enter your hospital city" : ""}
                        required
                        inputProps={{
                          "data-testid": "city"
                        }}
                        {...field}
                      />
                    }
                  />
                </Grid>

                {/* Hospital State / Province */}
                <Grid item container className={formField} xs={12} lg={6}>
                  <Typography className={label} mb={1} mt={1}>Hospital State/Province<span
                    title="Required field">&#42;</span></Typography>
                  <PatternSelect
                    control={control}
                    defaultValue=""
                    name="hospital_state"
                    fullWidth
                    error={!!errors.state}
                    testId={"state"}
                    helperText={errors.state ? "You must select a state or province." : ""}
                  >
                    {getAllStatesAndProvinces().map((state, index) =>
                      <MenuItem
                        key={index}
                        value={state}>
                        {state}
                      </MenuItem>
                    )}
                  </PatternSelect>
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                {/* Surgeon Name */}
                <Grid item container className={formField} xs={12} lg={6}>
                  <Typography className={label} mb={1} mt={1}>Surgeon Name</Typography>
                  <Controller
                    name="surgeon"
                    control={control}
                    render={({ field }) =>
                      <PatternTextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        inputProps={{
                          "data-testid": "surgeon"
                        }}
                        {...field}
                      />
                    }
                  />
                </Grid>

                {/* Surgeon Phone */}
                <Grid item container className={formField} xs={12} lg={6}>
                  <Typography className={label} mb={1} mt={1}>Surgeon Number</Typography>
                  <PatternPhoneField
                    name="surgeon_phone"
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    testId={"surgeon_phone"}
                  />
                </Grid>
              </Grid>


              <Grid item container spacing={3}>
                {/* Oncologist Name */}
                <Grid item container className={formField} xs={12} lg={6}>
                  <Typography className={label} mb={1} mt={1}>Oncologist&apos;s Name</Typography>
                  <Controller
                    name="oncologist"
                    control={control}
                    render={({ field }) =>
                      <PatternTextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        inputProps={{
                          "data-testid": "oncologist"
                        }}
                        {...field}
                      />
                    }
                  />
                </Grid>

                {/* Oncologist Phone */}
                <Grid item container className={formField} xs={12} lg={6}>
                  <Typography className={label} mb={1} mt={1}>Oncologist&apos;s Number</Typography>
                  <PatternPhoneField
                    name="oncologist_phone"
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    testId={"oncologist_phone"}
                    clearErrors={clearErrors}
                  />
                </Grid>
              </Grid>


              <Grid item container xs className="mt-10">
                <Typography
                  variant="h2"
                  className={subtitle}
                  align="left"
                >
                  Demographic Information
                </Typography>
              </Grid>

              <Divider component="div" className={dividerStyle} />

              {/* Birthday */}
              <Grid item container xs>
                <Typography
                  className={label} mb={1} mt={1}
                >
                  When is your birthday?<span
                    title="Required field">&#42;</span>
                </Typography>
              </Grid>
              <Grid item container className={formField}>
                <PatternDatePicker
                  name="birthday"
                  control={control}
                  defaultValue={birthday}
                  testId={"donation-info-birthday"}
                />
              </Grid>

              {/* Race */}
              <Grid item container className={formField}>
                <Typography
                  className={label} mb={1} mt={1}
                >
                  What is your race (select all that apply)<span
                    title="Required field">&#42;</span>
                </Typography>
                <PatternMultiSelect
                  control={control}
                  name="selected_races"
                  options={getRaces()}
                  fullWidth
                  testId={"selected-races"}
                  setValue={setValue}
                  onSelect={handleSelect}
                />
              </Grid>

              {/* Sex */}
              <Grid item container className={formField}>
                <Typography
                  className={label} mb={1} mt={1}
                >
                  What is your biological sex at birth?<span title="Required field">&#42;</span>
                </Typography>
                <PatternSelect
                  control={control}
                  defaultValue=""
                  name="sex"
                  testId={"sex"}
                  fullWidth
                >
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Prefer Not to Answer">Prefer Not to Answer</MenuItem>
                </PatternSelect>
              </Grid>

              <Grid item container className={formField}>
                <Typography
                  className={label} mb={1} mt={1}
                >
                  Additional Notes / Comments
                </Typography>

                <Controller
                  name="user_notes"
                  control={control}
                  render={({ field }) =>
                    <PatternMultilineTextField
                      variant="outlined"
                      color="primary"
                      fullWidth
                      error={!!errors.user_notes}
                      inputProps={{
                        "data-testid": "user_notes",
                        maxRows: 4
                      }}
                      {...field}
                    />
                  }
                />
              </Grid>

              {consentStudyName === "Cancer Cell Line Project" && <AboveTwentyOne />}

            </Grid>
          </Paper>
          <Grid item container justifyContent="flex-end" mb={4}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              data-testid={"submit"}
              className={button}
              disabled={isDonationInfoFormInvalid(isValid, surgeonPhone, oncologistPhone)}
            >
              Save and Continue
            </Button>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

DonationInfoForm.propTypes = {
  consentStudyName: PropTypes.string,
  birthday: PropTypes.instanceOf(Date),
  onSubmit: PropTypes.func
};

export default DonationInfoForm;