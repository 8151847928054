import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  PatternMultiChipTextInput,
  PatternPhoneField,
  PatternRadioQuestion,
  PatternTextField
} from "shared/components";
import { PatternSelect } from "shared/components/PatternSelect";
import { americanStates, canadianProvinces, stages } from "shared/constants/arrays";
import {
  defaultPersonalInfoValues,
  isEnrollmentPersonalInfoFormInvalid,
  personalInfoSchema
} from "shared/utils/formUtils";
import { i18n } from "shared/constants/i18n";
import { useEffect } from "react";
import { useStyles } from "./index";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

const YourInfoPersonalInfoForm = ({ onSubmit, cancers, onCancerSelect, numberOfStudiesBasedOnCancer }) => {

  const {
    title,
    paragraph,
    formWidth,
    label,
    contactInfo,
    button
  } = useStyles();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { isValid, errors }
  } = useForm({
    defaultValues: defaultPersonalInfoValues,
    mode: "all",
    resolver: yupResolver(personalInfoSchema)
  });

  const countrySelected = watch("country");
  const cancerDiagnosis = watch("cancer_diagnosis");
  const tumor_size = watch("tumor_size");
  const phoneNumber = watch("phone_number");

  useEffect(() => {
    if (cancerDiagnosis) {
      onCancerSelect(cancerDiagnosis);
    }
  }, [cancerDiagnosis]);

  return (
    <>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mb={5}
        xs={12}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
          className={formWidth}
        >
          <Paper
            elevation={3}
            sx={{
              padding: "60px",
              marginTop: "50px",
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: "40px"
            }}>

            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h1" className={title} align="center" mb={3}>
                  Your Information
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h3" align="center" className={paragraph} mb={6}>
                  {i18n.enrollment.eligibilityForm.contactInformation}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item container spacing={3}>
                  <Grid item lg={4} xs={12}>
                    <Typography className={label} mb={1} mt={3}>Cancer Diagnosis<span
                      title="Required field">&#42;</span></Typography>
                    <PatternSelect
                      control={control}
                      name="cancer_diagnosis"
                      error={!!errors.cancer_diagnosis}
                      helperText={errors.cancer_diagnosis ? "You must select a diagnosis." : ""}
                      value={cancers}
                      fullWidth
                      testId={"cancer-diagnosis"}
                    >
                      {cancers.map(
                        cancer =>
                          <MenuItem
                            key={cancer.id}
                            value={cancer.id}
                          >
                            {cancer.display}
                          </MenuItem>)}
                    </PatternSelect>
                  </Grid>

                  {/*Occurrence Type*/}
                  <Grid item container lg={4} xs={12}>
                    <Typography className={label} mb={1} mt={3}>Occurrence type</Typography>
                    <PatternSelect
                      control={control}
                      name="occurrence_type"
                      fullWidth
                      testId={"occurrence-type"}
                    >
                      <MenuItem value="primary">Primary</MenuItem>
                      <MenuItem value="recurrent">Recurrent</MenuItem>
                      <MenuItem value="metastatic">Metastatic</MenuItem>
                      <MenuItem value="unsure">Unsure</MenuItem>
                    </PatternSelect>
                  </Grid>

                  <Grid item lg={4} xs={12}>
                    <Typography className={label} mb={1} mt={3}>Stage<span
                      title="Required field">&#42;</span></Typography>
                    <PatternSelect
                      control={control}
                      name="cancer_stage"
                      value={stages}
                      fullWidth
                      testId="cancer-stage"
                    >
                      {stages.map(
                        stage =>
                          <MenuItem
                            key={stage.id}
                            value={stage.id}
                          >
                            {stage.display}
                          </MenuItem>)}
                    </PatternSelect>
                  </Grid>
                </Grid>


                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  {/*Tumor or Ascites Location*/}
                  <Grid item container lg={8} xs={12}>
                    <Typography className={label} mb={1} mt={3}>Tumor or Ascites Location</Typography>
                    {/*Tumor or Fluid Location*/}
                    <Controller
                      name="site"
                      control={control}
                      render={({ field }) =>
                        <PatternTextField
                          variant="outlined"
                          color="primary"
                          fullWidth
                          {...field}
                        />
                      }
                    />
                  </Grid>

                  {/*Tumor Size*/}
                  <Grid item container lg={4} xs={12}>
                    <Typography className={label} mb={1} mt={3}>Tumor size (cm)</Typography>
                    <Controller
                      name="tumor_size"
                      control={control}
                      render={({ field, fieldState: { error } }) =>
                        <PatternTextField
                          variant="outlined"
                          color="primary"
                          fullWidth
                          error={!!error}
                          helperText={error ? error.message : ""}
                          inputProps={{
                            "data-testid": "tumor-size",
                            type: "number",
                            step: ".01"
                          }}
                          {...field}
                        />
                      }
                    />
                  </Grid>

                  {tumor_size > 5 && numberOfStudiesBasedOnCancer > 1 && (
                    <Grid item container>
                      <PatternRadioQuestion
                        title="If available, would you like to donate tissue to multiple institutions."
                        field="donate_to_multiple"
                        control={control}
                        testId="donate-to-multiple"
                        isRequired={false}
                      />
                    </Grid>
                  )}

                  <Grid item container xs={12}>
                    <Grid item xs={12}>
                      <Typography className={label} mb={1} mt={1}>
                        Please list any systematic treatments or therapies you have received
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Controller
                        name="previous_treatments"
                        control={control}
                        render={({ field, fieldState: { error } }) =>
                          <PatternMultiChipTextInput
                            variant="outlined"
                            color="primary"
                            error={!!error}
                            fullWidth
                            helperText={error ? error.message : ""}
                            {...field}
                          />
                        }
                      />
                    </Grid>
                  </Grid>

                  {/*CONTACT INFORMATION*/}
                  <Grid item container justifyContent="flex-start">
                    <Typography className={contactInfo}>
                      Contact Information
                    </Typography>
                  </Grid>

                  {/*Your Street Address*/}
                  <Grid item container spacing={3}>
                    <Grid item container xs={12} lg={6}>
                      <Typography className={label} mb={1} mt={1}>Your Street Address</Typography>
                      <Controller
                        name="mailing_address_1"
                        control={control}
                        render={({ field }) =>
                          <PatternTextField
                            variant="outlined"
                            color="primary"
                            fullWidth
                            inputProps={{
                              "data-testid": "street-address",
                            }}
                            {...field}
                          />
                        }
                      />
                    </Grid>
                    <Grid item container xs={12} lg={6}>
                      <Typography className={label} mb={1} mt={1}>Phone Number<span
                        title="Required field">&#42;</span></Typography>
                      <PatternPhoneField
                        name="phone_number"
                        control={control}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </Grid>
                  </Grid>

                  {/*Your Street Address 2 (optional)*/}
                  <Grid item container>
                    <Typography className={label} mb={1}>Your Street Address 2 (optional)</Typography>
                    <Controller
                      name="mailing_address_2"
                      control={control}
                      render={({ field }) =>
                        <PatternTextField
                          variant="outlined"
                          color="primary"
                          fullWidth
                          inputProps={{
                            "data-testid": "street-address2",
                          }}
                          {...field}
                        />
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    {/*City*/}
                    <Grid item xs={12} lg={3}>
                      <Typography className={label} mb={1} >City</Typography>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field }) =>
                          <PatternTextField
                            variant="outlined"
                            color="primary"
                            fullWidth
                            inputProps={{
                              "data-testid": "city",
                            }}
                            {...field}
                          />
                        }
                      />
                    </Grid>

                    {/*Zip / Postcode*/}
                    <Grid item container xs={12} lg={3}>
                      <Typography className={label} mb={1}>Postal</Typography>
                      <Controller
                        name="postcode"
                        control={control}
                        render={({ field }) =>
                          <PatternTextField
                            variant="outlined"
                            color="primary"
                            fullWidth
                            inputProps={{
                              "data-testid": "zip",
                            }}
                            {...field}
                          />
                        }
                      />
                    </Grid>

                    {/*Country*/}
                    <Grid item container xs={12} lg={3}>
                      <Typography className={label} mb={1}>Country<span
                        title="Required field">&#42;</span></Typography>
                      <PatternSelect
                        id="county"
                        name="country"
                        control={control}
                        fullWidth
                        testId={"country-select"}
                        error={!!errors.country}
                        helperText={errors.country ? "You must select your country." : ""}
                      >
                        <MenuItem value="USA">USA</MenuItem>
                        <MenuItem value="Canada">Canada</MenuItem>
                      </PatternSelect>
                    </Grid>

                    {/*State / Province*/}
                    <Grid item container xs={12} lg={3}>
                      <Typography
                        className={label}
                        mb={1}
                      >
                        {countrySelected === "USA" ? "State" : "Province"}
                      </Typography>
                      <PatternSelect
                        control={control}
                        name="state"
                        fullWidth
                        testId={`state-province-${countrySelected}`}
                      >
                        {countrySelected === "USA" && americanStates.map((state, index) =>
                          <MenuItem
                            key={index}
                            value={state}>
                            {state}
                          </MenuItem>
                        )}
                        {countrySelected === "Canada" && canadianProvinces.map((province, index) =>
                          <MenuItem
                            key={index}
                            value={province}>
                            {province}
                          </MenuItem>
                        )}
                      </PatternSelect>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid item container justifyContent="flex-end">
            <Button
              className={button}
              type="submit"
              color="primary"
              variant="contained"
              disabled={isEnrollmentPersonalInfoFormInvalid(
                isValid,
                cancerDiagnosis,
                phoneNumber)
              }
            >
              Continue
            </Button>
          </Grid>
        </form>
      </Grid>
    </>

  );
};

YourInfoPersonalInfoForm.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  cancers: PropTypes.array,
  onCancerSelect: PropTypes.func,
  numberOfStudiesBasedOnCancer: PropTypes.number
};

export default YourInfoPersonalInfoForm;