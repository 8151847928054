import { Box, Divider, Grid, Grow, Typography, useMediaQuery } from "@mui/material";
import { useEnrollmentStepper } from "shared/hooks";
import { useStyles } from "./index";
import PropTypes from "prop-types";

import ConsentFormGrayIcon from "assets/new-icons/consent-form-gray.svg";
import ConsentFormGreenIcon from "assets/new-icons/consent-form-green.svg";
import DonationInfoGrayIcon from "assets/new-icons/donation-info-gray.svg";
import DonationInfoGreenIcon from "assets/new-icons/donation-info-green.svg";
import StudyInfoGrayIcon from "assets/new-icons/study-info-gray.svg";
import StudyInfoGreenIcon from "assets/new-icons/study-info-green.svg";
import YourInfoIcon from "assets/new-icons/your-info.svg";

const StepComponent = ({ iconGreen, iconGray, text, isStepDisabled, onClick }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xl"));

  const {
    stepTextGray,
    stepTextGreen,
    stepContainer
  } = useStyles();

  const handleClick = () => {
    if (!isStepDisabled) {
      onClick();
    }
  };

  return (
    <Grow in={true}>
      <Box textAlign="center" onClick={handleClick} className={!isStepDisabled ? stepContainer : ""}>
        <img
          src={isStepDisabled ? iconGray : iconGreen}
          alt={text}
          width={32}
          height={32}
          title={text}
        />
        <Typography
          className={isStepDisabled ? stepTextGray : stepTextGreen}
          hidden={isMobile}
        >
          {text}
        </Typography>
      </Box>
    </Grow>

  );
};

StepComponent.propTypes = {
  iconGray: PropTypes.object,
  iconGreen: PropTypes.object,
  isStepDisabled: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func
};

const EnrollmentStepper = ({ isEnrollmentPage }) => {

  const {
    dividerGray,
    dividerGreen
  } = useStyles();

  const { maxEnrollmentStep, updateEnrollmentStep } = useEnrollmentStepper();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  // todo conditional return after hook calls because of new react warning
  if (!isEnrollmentPage) {
    return null;
  }

  const handleStepSelect = (newValue) => updateEnrollmentStep({ currentEnrollmentStep: newValue });

  const isStepDisabled = (step) => !(maxEnrollmentStep >= step);

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justifyContent={isMobile ? "space-around" : "center"}
      sx={{ backgroundColor: "#F9F9F9" }}
    >
      <Grid item>
        <StepComponent
          isStepDisabled={false}
          text="Your Information"
          iconGray={YourInfoIcon}
          iconGreen={YourInfoIcon}
          onClick={() => handleStepSelect(0)}
        />
      </Grid>
      <Grid item hidden={isMobile}>
        <Divider
          className={dividerGreen}
          orientation="horizontal"
          variant="middle"
          flexItem
        />
      </Grid>
      <Grid item>
        <StepComponent
          isStepDisabled={isStepDisabled(1)}
          text="Study Information"
          iconGray={StudyInfoGrayIcon}
          iconGreen={StudyInfoGreenIcon}
          onClick={() => handleStepSelect(1)}
        />
      </Grid>
      <Grid item hidden={isMobile}>
        <Divider
          className={isStepDisabled(1) ? dividerGray : dividerGreen}
          orientation="horizontal"
          variant="middle"
          flexItem
        />
      </Grid>
      <Grid item>
        <StepComponent
          isStepDisabled={isStepDisabled(2)}
          text="Consent Form"
          iconGray={ConsentFormGrayIcon}
          iconGreen={ConsentFormGreenIcon}
          onClick={() => handleStepSelect(2)}
        />
      </Grid>
      <Grid item hidden={isMobile}>
        <Divider
          className={isStepDisabled(2) ? dividerGray : dividerGreen}
          orientation="horizontal"
          variant="middle"
          flexItem
        />
      </Grid>
      <Grid item>
        <StepComponent
          isStepDisabled={isStepDisabled(3)}
          text="Donation Information"
          iconGray={DonationInfoGrayIcon}
          iconGreen={DonationInfoGreenIcon}
          onClick={() => handleStepSelect(3)}
        />
      </Grid>
    </Grid>
  );
};

EnrollmentStepper.propTypes = {
  isEnrollmentPage: PropTypes.bool.isRequired
};

EnrollmentStepper.defaultTypes = {
  isEnrollmentPage: true
};

export default EnrollmentStepper;
