import { About } from "./About";
import {
  ConsentContainer,
  DonationInfoContainer,
  EnrollmentStepper,
  StudyInfoContainer,
  YourInfoContainer
} from "./Enrollment";
import { DashboardContainer as Dashboard } from "./Dashboard";
import { Enrollment } from "./Enrollment";
import { Faq } from "./Faq";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { LandingHome } from "./LandingHome";
import { LoginContainer as Login } from "./Login";
import { Root } from "./Root";
console.log('Environment:', process.env.REACT_APP_ENV);
export {
  Root,
  Header,
  Footer,
  LandingHome,
  ConsentContainer,
  DonationInfoContainer,
  StudyInfoContainer,
  YourInfoContainer,
  EnrollmentStepper,
  Dashboard,
  Login,
  About,
  Faq,
  Enrollment
};