import * as PropType from "prop-types";
import {
  Grid,
} from "@mui/material";
import { PatternCircularProgress } from "shared/components";
import { YourInfoErrorDialog } from "./YourInfoErrorDialog";
import {
  YourInfoPersonalInfoForm,
  YourInfoScreeningForm,
} from "./index";

const YourInfoView = ({
  userError,
  userStatus,
  // isLogged,
  isScreeningForm,
  email,
  onSubmitPersonalInfoForm,
  onSubmitScreeningForm,
  // onGoToNextStep,
  // onNavigateToAccount,
  cancers,
  onCloseErrorDialog,
  clearErrors,
  onCancerSelect,
  numberOfStudiesBasedOnCancer
}) => {

  if (userStatus === "loading") {
    return  <PatternCircularProgress />;
  }

  return (

    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <YourInfoErrorDialog
        isOpened={userStatus === "failed"}
        email={email}
        onClose={onCloseErrorDialog}
        error={userError}
        clearErrors={clearErrors}
      />

      {/*{ isLogged && (*/}
      {/*  <Grid item>*/}
      {/*    <Card>*/}
      {/*      <CardContent>*/}
      {/*        <Typography style={{fontSize: "1.6rem"}}>*/}
      {/*              Now that you&apos;re logged in, if you need to change any of your personal information,*/}
      {/*              please access it via the Account page. Otherwise, go on to the next step.*/}
      {/*        </Typography>*/}
      {/*      </CardContent>*/}
      {/*      <CardActions>*/}
      {/*        <Button onClick={onNavigateToAccount}>Account</Button>*/}
      {/*        <Button onClick={onGoToNextStep}>Next step</Button>*/}
      {/*      </CardActions>*/}
      {/*    </Card>*/}
      {/*  </Grid>*/}
      {/*)}*/}

      { isScreeningForm && (
        <YourInfoScreeningForm onSubmit={onSubmitScreeningForm} email={email} cancers={cancers}/>
      )}

      { !isScreeningForm && (
        <YourInfoPersonalInfoForm
          onSubmit={onSubmitPersonalInfoForm}
          cancers={cancers}
          onCancerSelect={onCancerSelect}
          numberOfStudiesBasedOnCancer={numberOfStudiesBasedOnCancer}
        />
      )}
    </Grid>
  );
};

YourInfoView.propTypes = {
  userError: PropType.object,
  userStatus: PropType.string,
  isLogged: PropType.bool,
  isScreeningForm: PropType.bool,
  email: PropType.string,
  onSubmitPersonalInfoForm: PropType.func,
  onSubmitScreeningForm: PropType.func,
  onGoToNextStep: PropType.func,
  onNavigateToAccount: PropType.func,
  cancers: PropType.array,
  onCloseErrorDialog: PropType.func,
  clearErrors: PropType.func,
  onCancerSelect: PropType.func,
  numberOfStudiesBasedOnCancer: PropType.number
};

export default YourInfoView;