import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  matchedProgramsContainer: {
    boxSizing: "border-box",
    "& :hover": {
      cursor: "pointer"
    }
  },
  selectedProgramStyle: {
    boxSizing: "border-box",
    borderRadius: "3px",
    outline: "3px solid #1F6EA3",
    shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  }
}));