import * as PropTypes from "prop-types";
import { AskQuestion } from "components/Enrollment/Consent/ConsentText/AskQuestion";
import { Button, Grid, Grow, Paper, useMediaQuery } from "@mui/material";
import { ConsentFinalStepForm } from "components/Enrollment/Consent/ConsentText/ConsentFinalStepForm";
import { ConsentHeaderMobile } from "./ConsentHeaderMobile";
import { ConsentStepsDesktop } from "./ConsentStepsDesktop";
import { ConsentText } from "./ConsentText";
import { ContactInformation } from "./ContactInformationDialog";
import { consent } from "./propTypes";
import { consentSchema } from "shared/utils/formUtils";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useStyles } from "./index";
import { yupResolver } from "@hookform/resolvers/yup";

const ConsentView = ({
  consent,
  consentStudyName,
  currentConsentStep,
  maxConsentStep,
  onStepClick,
  onStepMobileClick,
  onClickUnderstand,
  onClickUnderstandLastStep,
}) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  const {
    root,
    imgWrapper,
    img,
    consentForm,
    amendmentInfo,
    button
  } = useStyles();

  if (!consent) return null;

  const { study, sponsor, steps, amendment_info } = consent;

  const isLastStep = currentConsentStep === steps.length - 1;

  const defaultValues = {
    fullName: "",
    birthday: null
  };

  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(consentSchema)
  });

  return (
    <Grid item container justifyContent="center">

      <form
        onSubmit={handleSubmit(onClickUnderstandLastStep)}
        autoComplete="off"
        noValidate
        className={root}
      >
        <Paper elevation={3} sx={{ paddingBottom: "4rem", marginBottom: "4rem" }} className="mt-20">
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
          >

            {isMobile && (
              <ConsentHeaderMobile
                name={sponsor.name}
                logoUrl={sponsor.logoUrl}
                study={study}
                steps={steps}
                maxConsentStep={maxConsentStep}
                currentConsentStep={currentConsentStep}
                onStepMobileClick={onStepMobileClick}
              />
            )}

            {!isMobile && (
              <div
                className="w-full flex justify-end mt-10 mr-20"
              >
                <div>
                  <AskQuestion
                    onOpenHelp={handleOpen}
                  />
                </div>

              </div>
            )}

            <Grid
              item
              container
              direction={isMobile ? "column" : "row"}
              xs={11}
              className={consentForm}
              spacing={2}
            >
              {!isMobile && <ConsentStepsDesktop
                study={study}
                steps={steps}
                maxConsentStep={maxConsentStep}
                currentConsentStep={currentConsentStep}
                onStepClick={onStepClick}
              />}



              <ConsentText
                steps={steps}
                currentConsentStep={currentConsentStep}
              />

              {steps.map((step, index) => {
                if (index !== currentConsentStep) return null;
                if (isLastStep) {
                  return (
                    <Grid
                      key={index}
                      item
                      container
                      xs={12}
                      justifyContent="flex-end"
                    >
                      <ConsentFinalStepForm
                        control={control}
                        errors={errors}
                        consentStudyName={consentStudyName}
                      />
                    </Grid>
                  );
                }
              })}

              <ContactInformation
                isOpened={open}
                onClose={handleClose}
              />

              <Grid
                item
                container
                className={amendmentInfo}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: amendment_info }}
                >
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {steps.map((step, index) => {
          if (index !== currentConsentStep) return null;
          if (!isLastStep) {
            return (
              <Grid
                key={index}
                item
                container
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={button}
                  onClick={onClickUnderstand}
                  sx={{ textTransform: "none" }}
                >
                  {step.accept_button_text}
                </Button>
              </Grid>
            );
          }
          if (isLastStep) {
            return (
              <Grid
                key={index}
                item
                container
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                  className={button}
                  sx={{ textTransform: "none" }}
                >
                  {step.accept_button_text}
                </Button>
              </Grid>
            );
          }

        })}
      </form>

    </Grid>
  );
};

ConsentView.propTypes = {
  consent,
  consentStudyName: PropTypes.string,
  currentConsentStep: PropTypes.number,
  maxConsentStep: PropTypes.number,
  onStepClick: PropTypes.func,
  onStepMobileClick: PropTypes.func,
  onClickUnderstand: PropTypes.func,
  onClickUnderstandLastStep: PropTypes.func,
  onSubmit: PropTypes.func
};

export default ConsentView;