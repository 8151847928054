import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    height: "auto",
    maxHeight: "auto",
    width: "auto",
    textOverflow: "ellipsis",
    overflow: "scroll",
    overflowX: "none",
    wordWrap: "break-word",
    marginTop: "2rem",
    padding: "0 2rem",
    minHeight: "900px",
  },
  stepHeader: {
    fontSize: "2.4rem",
    fontWeight: 500,
    padding: "0 1rem",
    color: theme.palette.pattern.blue,
    margin: "1rem auto"
  },
  consentText: {
    fontSize: "2rem",
    fontWeight: 400,
    "& a": {
      textDecoration: "none",
      color: theme.palette.pattern.secondaryBg,
      "&:hover": {
        textDecoration: "underline"
      }
    }
  }
})
);

export default useStyles;