import { ConsentContainer } from "./Consent";
import { DonationInfoContainer } from "./DonationInfo";
import { EnrollmentStepper } from "components/Enrollment/EnrollmentStepper";
import { Grid } from "@mui/material";
import { StudyInfoContainer } from "./StudyInfo";
import { YourInfoContainer } from "./YourInfo";
import { useAppLocation } from "shared/hooks";
import PropTypes from "prop-types";

export const EnrollmentView = ({ currentEnrollmentStep }) => {
  const { isEnrollmentPage } = useAppLocation();

  return (
    <>
      <EnrollmentStepper isEnrollmentPage={isEnrollmentPage} />

      <Grid item container justifyContent="center" direction="column" className="w-5/6 mx-auto">
        {/*TODO Uncomment*/}
        {currentEnrollmentStep === 0 && <YourInfoContainer />}
        {currentEnrollmentStep === 1 && <StudyInfoContainer />}
        {currentEnrollmentStep === 2 && <ConsentContainer />}
        {currentEnrollmentStep === 3 && <DonationInfoContainer />}
        {/*TODO DELETE*/}
        {/*<DonationInfoContainer/>*/}
      </Grid >
    </>

  );
};

EnrollmentView.propTypes = {
  currentEnrollmentStep: PropTypes.number
};