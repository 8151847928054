import { Button, CardActions, CardContent, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PatternPhoneField, PatternTextField } from "shared/components";
import {
  contactInfoSchema,
  defaultContactInfoValues,
  isContactInfoFormInvalid
} from "shared/utils/formUtils";
import { useStyles } from "./index";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

const ContactInfoForm = ({ personal, onSubmit, onClick }) => {

  const { first_name, last_name, phone_number, email } = personal;

  const {
    formWidth,
    formField,
    actions
  } = useStyles();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { isValid }
  } = useForm({
    defaultValues: personal ? {
      first_name,
      last_name,
      phone_number,
      email
    } : defaultContactInfoValues,
    mode: "all",
    resolver: yupResolver(contactInfoSchema)
  });

  const phoneNumber = watch("phone_number");

  return (
    <CardContent>
      <Grid item container justifyContent="flex-start">
        <form
          className={formWidth}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
        >
          <Grid item container className={formField}>
            <Controller
              name="first_name"
              control={control}
              render={({ field, fieldState: { error } }) =>
                <PatternTextField
                  variant="outlined"
                  color="primary"
                  label="First Name"
                  error={!!error}
                  fullWidth
                  helperText={error ? error.message : ""}
                  required
                  {...field}
                />
              }
            />
          </Grid>

          {/*Last Name*/}
          <Grid item container className={formField}>
            <Controller
              name="last_name"
              control={control}
              render={({ field, fieldState: { error } }) =>
                <PatternTextField
                  variant="outlined"
                  color="primary"
                  label="Last Name"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ""}
                  required
                  {...field}
                />
              }
            />
          </Grid>

          {/*Phone Number*/}
          <Grid item container className={formField}>
            <PatternPhoneField
              name="phone_number"
              label="Phone Number"
              control={control}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              helperText={"Please enter your 10-digit phone number."}
              required
            />
          </Grid>

          {/*Email*/}
          <Grid item container className={formField}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) =>
                <PatternTextField
                  variant="outlined"
                  color="primary"
                  label="Email"
                  fullWidth
                  inputProps={{
                    minLength: 1,
                    maxLength: 100,
                    type: "email"
                  }}
                  required
                  error={!!error}
                  helperText={error ? error.message : ""}
                  {...field}
                />
              }
            />
          </Grid>
          <CardActions className={actions}>
            <Button
              color="inherit"
              type="submit"
              size="large"
              disabled={isContactInfoFormInvalid(isValid, phoneNumber)}
            >
                Save
            </Button>

            <Button
              color="inherit"
              size="large"
              onClick={onClick}
            >
                Cancel
            </Button>
          </CardActions>
        </form>
      </Grid>
    </CardContent>
  );
};

ContactInfoForm.propTypes = {
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  personal: PropTypes.object
};

export default ContactInfoForm;