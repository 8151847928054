import { Box, CardMedia } from "@mui/material";
import { useStyles } from "../index";
import PropTypes from "prop-types";
const ResearchTypeMedia = ({ media }) => {

  const { videoWrapper, aspectRatio16x9 } = useStyles();

  return (
    <Box>
      {media && media.length > 0 &&
        media.map((mediaItem, index) => {
          const { type, url, title } = mediaItem;
          const mediaType = type;
          // if (mediaType === "external-website") {
          //   return (
          //     <Box key={index}>
          //       <Link target="_blank" href={url}>{title}</Link>
          //     </Box>
          //   );
          // }
          if (mediaType === "youtube-video") {
            const videoUrl = url.replace("/watch?v=", "/embed/");
            return (
              <Box key={index} className={videoWrapper}>
                <CardMedia
                  component="iframe"
                  src={videoUrl}
                  title={title}
                  frameBorder="0"
                  allow="accelerometer;
                        clipboard-write;
                        encrypted-media;
                        gyroscope;
                        picture-in-picture"
                  allowFullScreen
                  className={aspectRatio16x9}
                />
              </Box>
            );
          }})}
    </Box>
  );
};

export default ResearchTypeMedia;

ResearchTypeMedia.propTypes = {
  media: PropTypes.array
};