import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";
import { useUser } from "shared/hooks";

const WelcomingCard = () => {

  const { root, title, paragraph, button } = useStyles();
  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const { sessionPersonalInfo } = useUser();

  return (
    <div className="w-full">
      <div className="text-white flex flex-col items-start space-y-6 justify-start md:w-1/2 w-3/4 mt-60 mx-auto md:ml-28">
        <div className="text-7xl font-bold text-left">
          Your Surgery or Fluid Drain Can Advance Cancer Research
        </div>
        <div className="text-3xl text-left pt-10 pb-32">
          With just 10 minutes, you can make sure your tumor tissue is sent to top cancer researchers.
        </div>
        <div className="w-full justify-center md:justify-start flex">
          <Button
            className={button}
            variant="contained"
            component={RouterLink}
            to="/enrollment"
          >
            {sessionPersonalInfo ? "Continue Enrollment" : "Get Started"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WelcomingCard;