import { Button, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const HeaderMenuDesktop = ({
  isRoot,
  isEnrollmentPage,
  sessionPersonalData,
  isFaqPage,
  isAboutPage,
  isWhyDonatePage,
  isSuccessPage,
  onLogout
}) => {

  const { buttonLinkNonLandingPage, buttonLinkLandingPage, btnLogin, btnNonLandingPage } = useStyles();

  const renderLoginAndLogoutButtons = () => {
    if (sessionPersonalData) {
      return (
        // TODO check if Logout button is on other pages
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={isRoot ? btnLogin : btnNonLandingPage}
            onClick={onLogout}
          >
            Logout
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={isRoot ? btnLogin : btnNonLandingPage}
            component={RouterLink}
            to="/login"
          >
            Login
          </Button>
        </Grid>
      );
    }
  };

  return (
    <>
      {(
        <>
          {!isWhyDonatePage &&
            <Grid item>
              <Button
                className={!isRoot ? buttonLinkNonLandingPage : buttonLinkLandingPage}
                component={RouterLink}
                to="/whyDonate"
              >
                Why donate tissue?
              </Button>
            </Grid>
          }

          {!isAboutPage &&
            <Grid item>
              <Button
                className={!isRoot ? buttonLinkNonLandingPage : buttonLinkLandingPage}
                component={RouterLink}
                to="/about"
              >
                About
              </Button>
            </Grid>
          }
          {!isFaqPage &&
            <Grid item>
              <Button
                className={!isRoot ? buttonLinkNonLandingPage : buttonLinkLandingPage}
                component={RouterLink}
                to="/faq"
              >
                FAQs
              </Button>
            </Grid>
          }
        </>
      )}
      {(!isEnrollmentPage || !isSuccessPage) && renderLoginAndLogoutButtons()}
    </>
  );
};

HeaderMenuDesktop.propTypes = {
  isRoot: PropTypes.bool.isRequired,
  isDashboardPage: PropTypes.bool.isRequired,
  isEnrollmentPage: PropTypes.bool.isRequired,
  isFaqPage: PropTypes.bool.isRequired,
  isAboutPage: PropTypes.bool.isRequired,
  isWhyDonatePage: PropTypes.bool.isRequired,
  isSuccessPage: PropTypes.bool.isRequired,
  sessionPersonalData: PropTypes.object,
  onLogout: PropTypes.func
};

HeaderMenuDesktop.defaultTypes = {
  isRoot: false,
  isDashboardPage: false,
  isEnrollmentPage: true,
  isFaqPage: false,
  isAboutPage: false,
  isWhyDonatePage: false,
  isSuccessPage: false,
  sessionPersonalData: null
};

export default HeaderMenuDesktop;