import { EnrollmentView } from "./Enrollment.view";
import { useCancers, useEnrollmentStepper } from "shared/hooks";
import { useEffect } from "react";

export const EnrollmentContainer = () => {

  const { currentEnrollmentStep } = useEnrollmentStepper();
  const { fetchCancers } = useCancers();

  useEffect(() => {
    fetchCancers();
  }, []);

  return <EnrollmentView currentEnrollmentStep={currentEnrollmentStep}/>;
};