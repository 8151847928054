import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { FooterLinks, useStyles } from "./index";
import { useAppLocation } from "shared/hooks";

const Footer = () => {

  const breakpoint = useMediaQuery(theme => theme.breakpoints.down("md"));

  const { isEnrollmentPage, isDashboardPage, isSuccessPage } = useAppLocation();
  const isNotVisible = !isEnrollmentPage && !isSuccessPage && !isDashboardPage;

  const { root, copyright, registered } = useStyles();

  return (
    <Grid container direction="column">
      <Paper square={true} className={root}>
        <Box mt={isNotVisible ? 5 : 0} mb={5} ml={5} mr={5}>
          <FooterLinks showLinks={isNotVisible} />
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Grid
              item
              container
              xs
              justifyContent={breakpoint ? "center" : "flex-end"}
            >
              <Box mt={5} mr={breakpoint ? 0 : 3}>
                <Typography className={copyright} variant={"subtitle2"} align="center">
                  © 2024 Rare Cancer Research Foundation
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              container
              xs={10}
              md={12}
              justifyContent={breakpoint ? "center" : "flex-end"}
            >
              <Box mt={2} mr={breakpoint ? 0 : 3}>
                <Typography className={registered} variant={"subtitle2"} align={breakpoint ? "center" : "right"}>
                  Pattern.org is a project of the Rare Cancer Research Foundation,
                  a 501(c)3 non-profit organization (EIN 46-3405526) registered in North Carolina.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default Footer;