import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  consentHelpBtn: {
    width: "auto",
    textTransform: "none",
    backgroundColor: theme.palette.pattern.consentHelp,
    color: theme.palette.pattern.blackDialog,
    "&:hover": {
      backgroundColor: theme.palette.pattern.disabledStep
    },
    "& svg": {
      fontSize: "2rem"
    }
  },
}));

export default useStyles;