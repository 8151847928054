export const serialize = (stateName, state) => {
  const serialized = JSON.stringify(state);
  sessionStorage.setItem(`session_${stateName}`, serialized);
};

export const deserialize = key => sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : null;

export const clearSession = () => {
  // sessionStorage.removeItem("session_personalInfo");
  sessionStorage.removeItem("session_personalData");
};

export const filterStudies = studyInfo => {
  const studyIds = getStudyIdsFromSession();

  if (studyIds && studyIds.length > 0) {
    return studyInfo.filter(study => !studyIds.includes(study.id));
  } else {
    return studyInfo;
  }
};

const getStudyIdsFromSession = () => {
  const personalData = deserialize("session_personalData");
  return personalData ? personalData.cases.map(singleCase => singleCase.donation_information.study_id) : null;
};