const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "3.5rem",
    fontWeight: 500,
    color: theme.palette.pattern.blue,
  },
  formWidth: {
    width: "75%"
  },
  tooltip: {
    color: theme.palette.pattern.gray,
    cursor: "pointer",
    "& svg": {
      fontSize: "2rem"
    }
  },
  paragraph: {
    fontSize: "1.8rem",
    fontWeight: 400,
    color: theme.palette.pattern.grayNew,
  },
  label: {
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  button: {
    width: "369px",
    height: "61px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.8rem",
    textTransform: "none"
  },
  contactInfo: {
    fontFamily: "Poppins",
    fontSize: "2.4rem",
    fontWeight: 500
  }
}));

export { useStyles };