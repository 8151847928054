const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: "Poppins",
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  title: {
    fontSize: "3.5rem",
    fontWeight: 500,
    color: theme.palette.pattern.blue,
  },
  formWidth: {
    width: "80%"
  },
  formField: {
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    color: theme.palette.pattern.grayTitle,
    fontFamily: "Poppins",
    fontSize: "2.4rem",
    fontWeight: 500
  },
  birthdayLabel: {
    color: theme.palette.pattern.gray,
    fontWeight: 400,
    fontSize: "1.6rem",
    margin: "16px 0"
  },
  paragraph: {
    fontSize: "1.8rem",
    fontWeight: 400,
    color: theme.palette.pattern.grayNew,
  },
  label: {
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.pattern.grayTitle,
  },
  button: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.8rem",
    textTransform: "none"
  },
  dividerStyle: {
    backgroundColor: theme.palette.pattern.dividerGray,
    borderBottomColor: theme.palette.pattern.dividerGray,
    height: "2px",
    width: "100%",
    marginBottom: "1rem"
  },
}));

export { useStyles };