import * as PropType from "prop-types";
import { CircularProgress, Grid, Grow, useMediaQuery } from "@mui/material";
import { useStyles } from "./index";

const Partners = ({ foundationPartners }) => {

  const { partnerLogo, partnerLogoSmall } = useStyles();
  const breakpoint = useMediaQuery(theme => theme.breakpoints.down("md"));

  if (foundationPartners && foundationPartners.length === 0) {
    return <CircularProgress style={{ marginBottom: "50px" }} />;
  }

  return (
    <div
      className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mx-auto "
    >
      {foundationPartners && foundationPartners.map(({ name, logo_http_url, occupies }, index) => {
        return (
          <div key={index} in={!!logo_http_url} className="flex justify-center">
            <img src={logo_http_url} className="w-3/4" alt={name} onClick={() =>
              window.open(occupies, "_blank")
            } />
          </div>
        );
      })}
    </div>
  );
};

export default Partners;

Partners.propTypes = {
  foundationPartners: PropType.array,
};