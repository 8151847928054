import { Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";

const useStyles = makeStyles(() => ({
  root: {
    "& div.MuiSelect-outlined": {
      height: "18px",
      paddingTop: "13.5px",
      paddingBottom: "13.5px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      fontSize: "1.5rem",
      fontWeight: 500,
      alignItems: "center",
      "& .MuiFormHelperText-root": {
        fontSize: "5rem",
        fontWeight: 500,
        color: "red !important"
      }
    }
  }
}));

const PatternSelect = ({
  name,
  label,
  control,
  children,
  helperText,
  testId,
  ...props
}) => {
  const labelId = `${name}-label`;

  const { root } = useStyles();

  return (
    <>
      <FormControl variant="outlined" {...props} >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          render={({ field }) =>
            <Select
              labelId={labelId}
              label={label}
              className={root}
              SelectDisplayProps={{ "data-testid": `${testId}` }}
              {...field}
            >
              {children}
            </Select>
          }
          name={name}
          control={control}
        />
      </FormControl>
      <FormHelperText
        style={{ color: "#d08405", fontWeight: 500, fontSize: "1.2rem", position: "absolute" }}
      >
        {helperText}
      </FormHelperText>
    </>
  );
};

PatternSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.any,
  helperText: PropTypes.string,
  children: PropTypes.node,
  testId: PropTypes.string
};

export default PatternSelect;