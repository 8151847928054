import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { GreenPanelSteps } from "./index";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";

const GreenPanel = () => {

  const { button } = useStyles();

  return (
    <div className="bg-pattern-blue">
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >

        <div className="mt-10 md:mt-20">

          <div className="text-5xl w-full text-center md:text-6xl font-bold text-white mt-10 mb-5">
            There are just 4 steps to participate:
          </div>

        </div>
        <GreenPanelSteps />
        {/*TODO to be removed*/}
        {/*<GreenPanelVideo/>*/}

        <Grid item xs>
          <Button
            className={button}
            variant="contained"
            component={RouterLink}
            to="/enrollment"
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default GreenPanel;