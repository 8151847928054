import { DashboardView } from "./index";
import { PatternCircularProgress } from "shared/components";
import { filterStudies } from "shared/utils/sessionStorageUtils";
import { useAppLocation, useEnrollment, useEnrollmentStepper, useUser } from "shared/hooks";
import { useEffect } from "react";

const DashboardContainer = () => {

  const { updateEnrollmentStep } = useEnrollmentStepper();
  const { studyInfo, fetchStudyInfoByCancer } = useEnrollment();
  const { sessionPersonalData, userData, userStatus, updateUser } = useUser();
  const { navigateTo } = useAppLocation();

  const hasNoAdditionalStudies = filterStudies(studyInfo).length === 0;

  useEffect(() => {
    if (hasNoAdditionalStudies) {
      updateEnrollmentStep({ currentEnrollmentStep: 1, maxEnrollmentStep: 1 });
    }
  }, []);

  let user = userData;

  if (!userData) {
    user = sessionPersonalData;
  }

  const handleSubmit = formData => updateUser(formData);

  const handleGoToAdditionalStudies = id => {
    updateEnrollmentStep({ currentEnrollmentStep: 1, maxEnrollmentStep: 1 });
    fetchStudyInfoByCancer(id);
    navigateTo("/enrollment");
  };

  if (
    userStatus === "fail" ||
    userStatus === "loading" ||
    !user || (!user?.personal && !user?.cases)) return <PatternCircularProgress />;

  return (
    <DashboardView
      userData={user}
      hasNoAdditionalStudies={hasNoAdditionalStudies}
      studyInfoList={studyInfo}
      onClick={handleGoToAdditionalStudies}
      onSubmit={handleSubmit}
    />
  );
};

export default DashboardContainer;