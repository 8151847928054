import * as PropType from "prop-types";
import { DonationInfoForm } from "./DonationInfoForm";
import {
  Grid,
} from "@mui/material";

const DonationInfoView = ({ onSubmitDonationInfoForm, birthday, consentStudyName }) => {

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <DonationInfoForm birthday={birthday} onSubmit={onSubmitDonationInfoForm} consentStudyName={consentStudyName}/>
    </Grid>
  );
};

DonationInfoView.propTypes = {
  onSubmitDonationInfoForm: PropType.func,
  birthday: PropType.instanceOf(Date),
  consentStudyName: PropType.string
};

export default DonationInfoView;