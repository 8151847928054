import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      fontSize: "4rem",
    },
  },
  paragraph: {
    color: theme.palette.pattern.grayNew,
    fontSize: "1.5rem",
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      fontSize: "2rem",
    },
  }
}));

export default useStyles;