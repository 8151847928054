import { LoginView } from "./index";
import { useUser } from "shared/hooks";

const LoginContainer = () => {
  const { submitEmail, submitPasscode, authStatus } = useUser();

  const handleSubmitEmail = ({ email }) => submitEmail(email);

  const handleSubmitPasscode = ({ passcode }) => {
    submitPasscode(passcode);
  };

  return (
    <LoginView status={authStatus} onSubmitEmail={handleSubmitEmail} onSubmitPasscode={handleSubmitPasscode}/>
  );
};

export default LoginContainer;